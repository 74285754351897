import React, { useEffect, useState } from "react";
import { resetPassword } from "action/AuthAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers";
import validate from "service/validation";
import "./style.scss";
import ErrorComponent from "component/common/ErrorComponent";
import keyIcon from "assets/svg/Reset-password-icon.svg";
import passResetImage from "../../../../assets/images/passResetImage.png";
import { validationRules } from "./validate";

const ResetPasswordClass = (props) => {
  const [passwordDetails, setPasswordDetails] = useState({
    password: "",
    reEnterNewPassword: "",
  });
  const [isButtonClicked, setisButtonClicked] = useState(false);
  const [error, setErrors] = useState({});
  const [isSuccessView, setisSuccessView] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [reEnterpasswordVisible, setReenterPasswordVisible] = useState(false);

  useEffect(() => {
    let { token } = props;
    if (!token) {
      history.push("/");
    } else {
      localStorage.setItem("forgotPassauthToken", token);
    }
  }, []);

  //handle Change
  const handleChange = (e) => {
    e.persist();
    const { name, value } = e.target || e || {};

    const tempErrors = { ...error };

    tempErrors[name] = undefined;
    setPasswordDetails({ ...passwordDetails, [name]: value });
    setErrors({ ...error, ...tempErrors });
  };

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());

    if (fieldInvalidList !== undefined) {
      const errors = {
        ...fieldInvalidList,
      };
      setErrors({ ...errors, ...fieldInvalidList });
    }

    return !fieldInvalidList;
  };

  //handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      ...passwordDetails,
    };
    if (!validateFields(body)) return;
    let forgotPassauthToken = localStorage.getItem("forgotPassauthToken");
    const payload = {
      token: forgotPassauthToken,
      password: passwordDetails.password,
    };
    setisButtonClicked(true);
    props
      .ResetPasswordApiCall(payload)
      .then(() => {
        setisButtonClicked(false);
        setisSuccessView(true);
        localStorage.removeItem("forgotPassauthToken");
        setPasswordDetails({
          password: "",
          reEnterNewPassword: "",
        });
      })
      .catch(() => {
        setisButtonClicked(false);
      });
  };

  return (
    <>
      {isSuccessView ? (
        <div className="forgot-password-area w-100">
          <div className="col-md-6 m-auto h-100">
            <div className="forgot-password py-md-5 p-3">
              <div className="row">
                <div className="col-12 mx-auto">
                  <div className="d-flex justify-content-center m-auto">
                    <img src={passResetImage} />
                  </div>
                  <p className="mb-3 fs-30 fw-700 text-center text-black-1a font-Roboto mt-4 pt-3 line-height-35">
                    Password Reseted Successfully
                  </p>
                  <p className="text-secondary line-height-19 fs-16 mb-4 pb-3 px-3 text-center">
                    We have reseted your password
                  </p>
                  <div className="col-12 d-flex justify-content-center">
                    <div className="col-5">
                      <button
                        type="submit"
                        className="btn_login mt-1 pt-3"
                        onClick={() => history.push("/auth/login")}
                      >
                        Login
                      </button>
                    </div>
                  </div>
                  <div className="bottom_label_login d-flex justify-content-center mt-4 pt-5">
                    <div className="forget-pass">
                      <p className="mb-0 fw-500 text-secondary">
                        Something wrong?
                        <span
                          className="fs-16 fw-700 font-Roboto text-blue-2c mb-0 cursor-pointer pl-1"
                          onClick={() => history.push("/auth/forgot-password")}
                        >
                          Help
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="reset-password-area w-100">
          <div className="col-md-6 m-auto h-100">
            <div className="reset-password py-md-5 p-3">
              <div className="row">
                <div className="col-8 mx-auto">
                  <form onSubmit={handleSubmit} className="w-100">
                    <div className="d-flex justify-content-center mb-4">
                      <img src={keyIcon} alt="reset-icon" />
                    </div>
                    <h1 className="mb-3 text-center">Create Password</h1>
                    <p className="description mb-4 pb-3 px-3">
                      Your password must be different from the previous password
                    </p>
                    <label className="label_text_login pb-1">
                      Enter new password
                    </label>
                    <div className="mb-3 pb-1">
                      <div className="position-relative">
                        <input
                          name="password"
                          type={passwordVisible ? "text" : "password"}
                          className="input-area"
                          value={passwordDetails.password}
                          onChange={handleChange}
                        />
                        <div className="input-group-addon right">
                          <i
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            className={`cursor-pointer icon-${passwordVisible ? "eye" : "eye-hide"
                              } fs-18`}
                          ></i>
                        </div>
                      </div>
                      {error.password && (
                        <ErrorComponent message={error.password[0]} />
                      )}
                    </div>
                    <label className="label_text_login pb-1">
                      Confirm new password
                    </label>
                    <div className="mb-3 pb-1">
                      <div className="position-relative">
                        <input
                          name="reEnterNewPassword"
                          type={reEnterpasswordVisible ? "text" : "password"}
                          className="input-area"
                          value={passwordDetails.reEnterNewPassword}
                          onChange={handleChange}
                        />
                        <div className="input-group-addon right">
                          <i
                            onClick={() =>
                              setReenterPasswordVisible(!reEnterpasswordVisible)
                            }
                            className={`cursor-pointer icon-${reEnterpasswordVisible ? "eye" : "eye-hide"
                              } fs-18`}
                          ></i>
                        </div>
                      </div>
                      {error.reEnterNewPassword && (
                        <ErrorComponent message={error.reEnterNewPassword[0]} />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="btn_login mt-4 pt-3"
                      disabled={isButtonClicked}
                    >
                      Send confirmation
                    </button>
                    <div className="bottom_label_login pb-3 d-flex justify-content-center mt-4">
                      <div className="forget-pass d-flex align-items-center">
                        <p
                          className="mb-0 fw-500 cursor-pointer"
                          onClick={() => history.push("/auth/forgot-password")}
                        >
                          Go to email?
                        </p>
                        <span
                          className="fs-16 fw-500 text-blue-2c mb-0 cursor-pointer pl-1 line-height-19"
                          onClick={() => history.push("/auth/login")}
                        >
                          Login
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ResetPasswordApiCall: resetPassword,
    },
    dispatch
  );
};

let component = ResetPasswordClass;

export const ResetPassword = connect(null, mapDispatchToProps)(component);
