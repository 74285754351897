import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { NavLink } from "react-router-dom";
import { useTheme } from "@material-ui/core/styles";
import Logo from "assets/svg/logo.svg";
import User from "assets/icons/user.png";
import ShoppingBag from "assets/icons/shopping.png";
import Server from "assets/icons/server.png";
import Package from "assets/icons/package.png";
import Folder from "assets/icons/folder.png";
import UserBlue from "assets/icons/user-blue.png";
import ShoppingBlue from "assets/icons/shopping-blue.png";
import ServerBlue from "assets/icons/server-blue.png";
import PackageBlue from "assets/icons/package-blue.png";
import FolderBlue from "assets/icons/folder-blue.png";
import LogOut from "assets/icons/log-out.png"
import { getAccessdata } from "service/helperFunctions";
import { logout } from "service/utilities";

const navLink = [
  {
    to: "/dashboard/userManagement",
    label: "User Management",
    iconName: User,
    iconTransparent: UserBlue,
    serviceName: "user-management",
  },
  {
    to: "/dashboard/retailer",
    label: "Retailer management",
    iconName: ShoppingBag,
    iconTransparent: ShoppingBlue,
    serviceName: "retailer-management",
  },
  {
    to: "/dashboard/fileManagement",
    label: "File management",
    iconName: Folder,
    iconTransparent: FolderBlue,
    serviceName: "file-management",
  },
  {
    to: "/dashboard/fileUpload",
    label: "Product Management",
    iconName: Package,
    iconTransparent: PackageBlue,
    serviceName: "product-management",
  },
  {
    to: "/dashboard/transaction",
    label: "Transaction information",
    iconName: Server,
    iconTransparent: ServerBlue,
    serviceName: "transaction-management",
  },
];

function Sidebar({ classes, window, location }) {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const pathName = location.pathname;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <div className="text-center pt-4 pb-5">
          <img src={Logo} alt="logo"></img>
        </div>
      </div>
      <List className={classes.nav}>
        {navLink.map(
          ({ to, label, iconName, iconTransparent, serviceName }, index) => {
            let access = getAccessdata(serviceName);
            let viewStatus = false;
            viewStatus = access.viewAccess ? access.viewAccess : false;
            return (
              viewStatus && (
                <NavLink key={index} to={to}>
                  <ListItem button>
                    <ListItemIcon>
                      {pathName && pathName.includes(to) ? (
                        <img src={iconTransparent} alt="iconTransparent"></img>
                      ) : (
                        <img src={iconName} alt="iconName"></img>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={label}
                      style={{
                        textDecoration: "none",
                        color: `${pathName && pathName.includes(to)
                          ? "#1A4CA0"
                          : "#6D7480"
                          }`,
                      }}
                    />

                  </ListItem>
                </NavLink>

              )
            );
          }
        )}
      </List>
      <div className="mt-5 pt-5 p-4 d-flex align-items-center">
        <div className="mt-5 pt-5 d-flex" onClick={() => logout()}>
          <span><img src={LogOut} /></span>
          <p className="mb-0 ml-3 fs-16 fw-500 cursor-pointer text-secondary">Logout</p>
        </div>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
}

export default Sidebar;
