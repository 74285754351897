import { retailerManagementApi } from "../service/apiVariables";
import { addQuery } from "service/helperFunctions";

//get retailer data
export const getAllRetailApi =
    (query) =>
        (dispatch, getState, { api, Toast }) => {
            return new Promise((resolve, reject) => {
                addQuery(query, retailerManagementApi.getAllRetailer);
                api({ ...retailerManagementApi.getAllRetailer })
                    .then((data) => {
                        resolve(data);
                    })
                    .catch(({ }) => {
                        reject(Toast({ type: "error", message: "Error" }));
                    });
            });
        };

//delete retailer
export const retailerDeleteApi =
    (emailId) =>
        (dispatch, getState, { api, Toast }) => {
            return new Promise((resolve, reject) => {
                let { deleteRetailer } = retailerManagementApi;
                deleteRetailer.emailId = emailId;
                api({ ...retailerManagementApi.deleteRetailer })
                    .then((data) => {
                        resolve(data);
                        Toast({ type: "success", message: "Retailer Deleted successfully" })
                    })
                    .catch(({ }) => {
                        reject(Toast({ type: "error", message: "Error" }));
                    });
            });
        };

//add new retailer
export const addRetailer =
    (body) =>
        (dispatch, getState, { api, Toast }) => {
            return new Promise((resolve, reject) => {
                api({ ...retailerManagementApi.addNewRetailer, body })

                    .then((data) => {
                        Toast({ type: "success", message: "Retailer Added successfully" })
                        resolve(data);

                    })
                    .catch(({ message }) => {
                        reject(Toast({ type: "error", message }));
                    });
            });
        };

//update retailer
export const updateRetailer =
    (body) =>
        (dispatch, getState, { api, Toast }) => {
            return new Promise((resolve, reject) => {
                api({ ...retailerManagementApi.updateRetailer, body })
                    .then((data) => {
                        Toast({ type: "success", message: "Retailer Updated successfully" })
                        resolve();
                    })
                    .catch(({ message }) => {
                        reject(Toast({ type: "error", message }));
                    });
            });
        };