//validation Rules
export const validationRules = () => {
  let passwordValidation = {
    format: {
      pattern:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!_#%*?&])[A-Za-z\d@_#$!%*?&]*$/,
      flags: "i",
      message:
        "^Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
    },
    length: {
      minimum: 8,
      tooShort: "^Must be atleast 8 characters",
      maximum: 15,
      tooLong: "^Must contain less than 15 character",
    },
  };
  return {
    password: {
      presence: {
        allowEmpty: false,
        message: "^Password is required",
      },
      ...passwordValidation,
    },
    reEnterNewPassword: {
      presence: {
        allowEmpty: false,
        message: "^Confirm password is required",
      },
      equality: {
        attribute: "password",
        message: "^Both password must match",
        comparator: function (v1, v2) {
          return JSON.stringify(v1) === JSON.stringify(v2);
        },
      },
    },
  };
};
