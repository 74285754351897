import { productApi } from "../service/apiVariables";
import { addQuery } from "service/helperFunctions";

//product api data
export const productApiUpload =
  (query) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        addQuery(query, productApi.getAllProduct);
        api({ ...productApi.getAllProduct })
          .then((data) => {
            resolve(data);
          })
          .catch(({ }) => {
            reject(Toast({ type: "error", message: "Error" }));
          });
      });
    };

//product delete
export const productDeleteApi =
  (id) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        let { deleteProduct } = productApi;
        deleteProduct.id = id;
        api({ ...productApi.deleteProduct })
          .then((data) => {
            resolve(data);
            Toast({ type: "success", message: "Product Deleted Successfully" })
          })
          .catch(({ }) => {
            reject(Toast({ type: "error", message: "Error" }));
          });
      });
    };
