import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { LoginComp } from "component/auth/login/index";
import "../../component/auth/button.scss";
export class LoginClass extends Component {
  render() {
    return (
      <form>
        <div className="shadow-lg rounded d-flex align-items-center flex-column">
          <LoginComp />
        </div>
      </form>
    );
  }
}
export const Login = connect(null, null)(LoginClass);
