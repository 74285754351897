import React, { useEffect, useState } from "react";
import { CommonPageLoader, TableWrapper, NormalSearch } from "component/common";
import { userManagementHeader } from "service/helpers/Constants";
import { NormalButton } from "component/common/NormalButton";
import { NormalSelect } from "component/common/NormalSelect";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SearchIcon from "@material-ui/icons/Search";
import { getUserList, deleteUser, updateUser } from "action/UserMangementAct";
import moment from "moment";
import { DeletePopup } from "component/common/Modal/DeletePopup";
import deleteIcon from "assets/images/delete-user.svg";
import UserCreateEditPopup from "component/Dashboard/UserManagement/UserCreateEditPopup";

const statusOption = [
  { label: "Enabled", value: true },
  { label: "Disabled", value: false },
];

function UserManagement({
  getUserListApi,
  deleteUserApiCall,
  updateUserApi,
  access: { createAccess = false, updateAccess = false, deleteAccess = false },
}) {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [paginationConfig, setPaginationConfig] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteEmailId, setEmailId] = useState(null);
  const [isPopupLoading, setIsPopupLoading] = useState(false);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [userData, setuserData] = useState(null);
  const [isViewUser, setIsViewUser] = useState(false);

  useEffect(() => {
    fetchUsersList();
  }, [page, searchValue, pageLimit]);

  //fetch Users List
  const fetchUsersList = () => {
    setLoading(true);
    let query = {
      pageLimit,
      pageNo: page,
      search: searchValue,
    };
    getUserListApi(query)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          setPaginationConfig(data.pageMeta);
          setUserList(data.usersList);
          setLoading(false);
        } else {
          setUserList([]);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //handle Delete File
  const handleDeleteFile = () => {
    if (deleteEmailId) {
      setIsPopupLoading(true);
      deleteUserApiCall(deleteEmailId)
        .then(() => {
          handleToggle();
          fetchUsersList();
          setIsPopupLoading(false);
        })
        .catch(() => {
          setIsPopupLoading(false);
        });
    }
  };

  //handle Toggle
  const handleToggle = (emailId) => {
    if (emailId) {
      setEmailId(emailId);
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setEmailId(null);
    }
  };

  //handle Edit
  const handleEdit = (editData) => {
    setuserData(editData);
    setIsAddPopupOpen(true);
  };

  //handle Input
  const handleInput = (index) => {
    userList[index].status = !userList[index].status;
    setUserList([...userList]);
    let body = {
      _id: userList[index]._id,
      status: userList[index].status,
      emailId: userList[index].emailId,
    };
    updateUserApi(body)
      .then(() => {
        setUserList(userList);
      })
      .catch(() => {
        userList[index].status = !userList[index].status;
        setUserList([...userList]);
      });
  };

  return (
    <div className="row align-items-start file-management">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <NormalSearch
            placeholder={"Search"}
            value={searchValue}
            name={"searchValue"}
            onChange={(e) => setSearchValue(e.target.value)}
            icon={<SearchIcon />}
          />
          {createAccess ? (
            <NormalButton
              label="Create New User"
              normal
              className="ml-auto"
              onClick={() => setIsAddPopupOpen(true)}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="col-12">
        <TableWrapper
          headerDetails={userManagementHeader}
          pageMeta={true}
          className="time-off"
          paginationShow={true}
          paginationConfig={paginationConfig}
          handlePageChange={setPage}
          ismanagementPage={true}
          handlePageSize={(size) => {
            setPage(1);
            setPageLimit(size);
          }}
          paginationChange={setPage}
        >
          {!loading ? (
            <>
              {userList.length > 0 ? (
                userList.map((item, index) => {
                  let { createdAt, name, emailId, status } = item;
                  return (
                    <tr className="my-2" key={index}>
                      <td
                        className={`text-center ${
                          !status ? "disabled-color" : ""
                        }`}
                      >
                        {(page - 1) * 10 + index + 1}
                      </td>
                      <td>
                        {updateAccess ? (
                          <div
                            onClick={() => {
                              status && handleEdit(item);
                            }}
                            className={`d-flex align-items-center h-100 fs-14 ${
                              !status
                                ? "disabled-color"
                                : "text-blue-2c cursor-pointer text-underline"
                            } fw-500 justify-content-center`}
                          >
                            {name}
                          </div>
                        ) : (
                          <div
                            className={`d-flex align-items-center h-100 fs-14 ${
                              !status ? "disabled-color" : "text-blue-2c"
                            } fw-500 justify-content-center`}
                          >
                            {name}
                          </div>
                        )}
                      </td>
                      <td>
                        <div
                          className={`d-flex align-items-center h-100 fs-14 justify-content-center ${
                            !status ? "disabled-color" : "text-secondary"
                          }`}
                        >
                          {emailId}
                        </div>
                      </td>
                      <td>
                        <div
                          className={`d-flex align-items-center h-100 fs-14 justify-content-center ${
                            !status ? "disabled-color" : "text-secondary"
                          }`}
                        >
                          {createdAt
                            ? moment(createdAt).format("MM/DD/YYYY")
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div
                          className={`d-flex align-items-center h-100 fs-14 justify-content-center ${
                            !status ? "disabled-color" : "text-secondary"
                          }`}
                        >
                          {createdAt ? moment(createdAt).format("H:mm A") : "-"}
                        </div>
                      </td>
                      <td>
                        <div
                          className={`d-flex align-items-center h-100 fs-14 fw-600 justify-content-center ${
                            !status ? "disabled-color" : "text-success"
                          }`}
                        >
                          {status ? "Active" : "In-active"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 table-select_dropdown justify-content-center">
                          <NormalSelect
                            value={status}
                            handleChange={() => handleInput(index)}
                            name="role"
                            disabled={!updateAccess ? true : false}
                            isBoxShadow
                            arrow={true}
                            isTableIcon
                            options={statusOption}
                            isSearchable={false}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center">
                          {deleteAccess ? (
                            <NormalButton
                              label="Delete"
                              outlineDanger
                              onClick={() => {
                                handleToggle(emailId);
                              }}
                            />
                          ) : (
                            ""
                          )}
                          <NormalButton
                            label="View"
                            isViewTableButton
                            onClick={() => {
                              setIsViewUser(true);
                              handleEdit(item);
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td colSpan="8" className="text-center">
                  No data
                </td>
              )}
            </>
          ) : (
            <tr>
              <td colSpan="8" className="text-center">
                <CommonPageLoader />
              </td>
            </tr>
          )}
        </TableWrapper>
      </div>
      <DeletePopup
        isOpen={isOpen}
        title="Confirm detele user records?"
        toggle={() => handleToggle()}
        deleteIcon={deleteIcon}
        handleChange={handleDeleteFile}
        isPopupLoading={isPopupLoading}
      />
      <UserCreateEditPopup
        isOpen={isAddPopupOpen}
        toggle={() => {
          setuserData(null);
          setIsViewUser(false);
          setIsAddPopupOpen(false);
        }}
        isEdit={userData ? true : false}
        isView={isViewUser}
        userData={userData}
        handleCloseFunc={() => fetchUsersList()}
      />
    </div>
  );
}
const mapdispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserListApi: getUserList,
      deleteUserApiCall: deleteUser,
      updateUserApi: updateUser,
    },
    dispatch
  );
};

export default connect(null, mapdispatchToProps)(UserManagement);
