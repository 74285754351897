export const validationRules = () => {
  return {
    emailId: {
      presence: {
        allowEmpty: false,
        message: "^Email is required",
      },
      format: {
        pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.[a-zA-Z0-9]{2,4})*$/,
        flags: "i",
        message: "^Please enter valid email id",
      },
    },
    name: {
      presence: {
        allowEmpty: false,
      },
      length: {
        minimum: 3,
        tooShort: "must contain alteast 3 character",
        maximum: 50,
        tooLong: "must contain less than 50 character",
      },
    },
    contactPerson: {
      presence: {
        allowEmpty: false,
      },
      length: {
        minimum: 3,
        tooShort: "must contain alteast 3 character",
        maximum: 50,
        tooLong: "must contain less than 50 character",
      },
    },
    siteUrl: {
      presence: {
        allowEmpty: false,
      },
      length: {
        minimum: 3,
        tooShort: "must contain a Site Link",
        maximum: 50,
        tooLong: "must contain less than 50 character",
      },
    },

    mobileNo: {
      presence: {
        allowEmpty: false,
        message: "^Phone Number is required",
      },
      length: {
        maximum: 12,
        tooLong: "^Mobile number must contain less than 12 character",
      },
    },
    address: {
      presence: {
        allowEmpty: false,
      },
      length: {
        minimum: 3,
        tooShort: "must contain alteast 10 character",
        maximum: 50,
        tooLong: "must contain less than 50 character",
      },
    },
    pincode: {
      presence: {
        allowEmpty: false,
        message: "^Pincode is required",
      },
      length: {
        minimum: 6,
        tooShort: "must contain alteast 6 numbers",
        maximum: 6,
        tooLong: "must contain 6 numbers only ",
      },
    },
  };
};
