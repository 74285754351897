import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getRolesList,
  getRetailerList,
  addUser,
  updateUser,
} from "action/UserMangementAct";
import { Modal, ModalBody } from "reactstrap";
import { NormalButton, NormalInput, NormalSelect } from "component/common";
import validate from "service/validation";
import "./style.scss";
import ErrorComponent from "component/common/ErrorComponent";
import { CustomPhoneInput } from "component/common/CustomPhoneInput";
import { Toast } from "service/toast";
import { validationRules } from "./validate";

function UserCreateEditPopup(props) {
  let {
    className = "modal-dialog-centered user-popup",
    isOpen = false,
    toggle,
    getRolesListApi,
    getRetailerListApi,
    addUserApi,
    updateUserApi,
    handleCloseFunc,
    userData,
    isView = false,
    isEdit = false,
  } = props;

  const [userDetail, setUserDetail] = useState({
    name: "",
    emailId: "",
    role: "",
    retailerList: "",
    mobileNo: "",
  });
  const [errors, setErrors] = useState([]);
  const [isButtonClicked, setisButtonClicked] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [retailerOptions, setRetailerOptions] = useState([]);

  const [retailerSelectedList, setretailerSelectedList] = useState([]);

  //handle Input
  const handleInput = (e) => {
    const { name, value } = e.target || e || {};

    const tempErrors = { ...errors };

    tempErrors[name] = undefined;
    setUserDetail({ ...userDetail, [name]: value });
    setErrors({ ...errors, ...tempErrors });
  };

  //handle dropdown changes
  const handleInputChange = (e) => {
    let { value } = e.target;
    let retailerList = [];
    let tempSelectedList = [];
    value.map((list) => {
      retailerList.push(list);
      tempSelectedList.push(list.value);
    });
    userDetail.retailerList = [...retailerList];
    setUserDetail({ ...userDetail });
    setretailerSelectedList([...tempSelectedList]);
  };

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());

    if (fieldInvalidList !== undefined) {
      const errors = {
        ...fieldInvalidList,
      };
      setErrors({ ...errors, ...fieldInvalidList });
    }

    return !fieldInvalidList;
  };

  useEffect(() => {
    if (isOpen) {
      getRolesListApiFunc();
      getRetailerListApiFunc();
      if (userData) {
        let editingData = userData;
        let selectedList = [];
        editingData.retailerList.map((list) => {
          list.value = list._id;
          list.label = list.name;
          selectedList.push(list._id);
        });
        if (editingData.role && editingData.role._id) {
          editingData.role = editingData.role._id;
        }
        if (editingData.retailerList.length === selectedList.length) {
          setretailerSelectedList(selectedList);
          setUserDetail(editingData);
        }
      }
    }
  }, [isOpen, userData]);

  //get Roles List Api Func
  const getRolesListApiFunc = () => {
    getRolesListApi().then(({ data }) => {
      let resultData = [];
      data.map((list) => {
        resultData.push({ value: list._id, label: list.role });
      });
      if (resultData.length === data.length) {
        setRoleOptions([...resultData]);
      }
    });
  };

  //get Retailer List Api Func
  const getRetailerListApiFunc = () => {
    getRetailerListApi().then(({ data }) => {
      let resultData = [];
      if (data) {
        data.usersList.map((list) => {
          resultData.push({ value: list._id, label: list.name });
        });
        if (resultData.length === data.usersList.length) {
          setRetailerOptions([...resultData]);
        }
      }
    });
  };

  //handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      ...userDetail,
    };
    if (!validateFields(body)) return;
    if (retailerSelectedList.length !== 0) {
      body.retailerList = retailerSelectedList;
      setisButtonClicked(true);
      if (isEdit) {
        delete body.createdAt;
        updateUserApi(body)
          .then(() => {
            handleClose();
            handleCloseFunc();
            setisButtonClicked(false);
          })
          .catch(() => {
            setisButtonClicked(false);
          });
      } else {
        addUserApi(body)
          .then(() => {
            handleClose();
            handleCloseFunc();
            setisButtonClicked(false);
          })
          .catch(() => {
            setisButtonClicked(false);
          });
      }
    } else {
      Toast({ type: "error", message: "Please add retailer" });
    }
  };

  //handle Close
  const handleClose = () => {
    setUserDetail({
      name: "",
      emailId: "",
      role: "",
      retailerList: "",
      mobileNo: "",
    });
    setretailerSelectedList([]);
    props.toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} size="lg">
      <ModalBody className="p-0">
        <div className="d-flex justify-content-end">
          <i
            className="icon-close fs-16 cursor-pointer"
            onClick={() => handleClose()}
          ></i>
        </div>
        <div>
          <p className="text-blue-2c fs-24 text-center line-height-24 fw-600">
            {isView
              ? "View Detail"
              : isEdit
                ? "Update User"
                : "Create New Profile"}
          </p>
        </div>
        <div className="row">
          <div className="col-6">
            <NormalInput
              label="Name *"
              value={userDetail.name}
              name="name"
              onChange={handleInput}
              disabled={isView}
            />
            {errors.name && <ErrorComponent message={errors.name[0]} />}
          </div>

          <div className="col-6">
            <CustomPhoneInput
              label="Contact number *"
              value={userDetail.mobileNo}
              onChange={(phone) => {
                errors.mobileNo = undefined;
                setErrors(errors);
                setUserDetail({ ...userDetail, mobileNo: phone });
              }}
              disabled={isView}
            />
            {errors.mobileNo && <ErrorComponent message={errors.mobileNo[0]} />}
          </div>
          <div className="col-6">
            <NormalInput
              label="email id *"
              value={userDetail.emailId}
              name="emailId"
              onChange={handleInput}
              disabled={isView}
            />
            {errors.emailId && <ErrorComponent message={errors.emailId[0]} />}
          </div>
          <div className="col-6">
            <NormalSelect
              label="Role *"
              value={userDetail.role}
              handleChange={handleInput}
              name="role"
              arrow={true}
              options={roleOptions}
              disabled={isView}
              isSearchable={false}
            />
            {errors.role && <ErrorComponent message={errors.role[0]} />}
          </div>
          <div className="col-6">
            <NormalSelect
              label="Access *"
              value={userDetail.retailerList}
              name="retailerList"
              handleChange={handleInputChange}
              arrow={true}
              isMulti={true}
              disabled={isView}
              options={retailerOptions}
            />
          </div>
        </div>
        {!isView ? (
          <div className="d-flex justify-content-center mt-5">
            <NormalButton
              greyBg
              label="Back"
              className="mr-4 ml-auto"
              onClick={() => handleClose()}
              disabled={isButtonClicked}
            />
            <NormalButton
              normal
              label={isButtonClicked ? "Loading..." : isEdit ? "Update" : "Add"}
              onClick={handleSubmit}
              disabled={isButtonClicked}
            />
          </div>
        ) : (
          ""
        )}
      </ModalBody>
    </Modal>
  );
}
const mapdispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRolesListApi: getRolesList,
      getRetailerListApi: getRetailerList,
      addUserApi: addUser,
      updateUserApi: updateUser,
    },
    dispatch
  );
};

export default connect(null, mapdispatchToProps)(UserCreateEditPopup);
