const routers = [
  {
    path: "/",
    exact: true,
    redirect: "/auth/login",
  },
  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "Login",
        path: "/login",
        auth: false,
        exact: true,
      },
      {
        component: "Forgotpassword",
        path: "/forgot-password",
        auth: false,
        exact: true,
      },
      {
        component: "Resetpassword",
        path: "/reset-password",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/dashboard",
    auth: false,
    exact: false,
    childrens: [
      {
        component: "Usermanagement",
        path: "/usermanagement",
        componentPath: "pages/Dashboard/Usermanagement",
        name: "Usermanagement",
        auth: false,
        exact: true,
      },
      {
        component: "FileManagement",
        path: "/fileManagement",
        componentPath: "pages/Dashboard/FileManagement",
        name: "FileManagement",
        auth: false,
        exact: true,
      },
      {
        component: "FileUpload",
        path: "/fileUpload",
        componentPath: "pages/Dashboard/FileUpload",
        name: "FileUpload",
        auth: false,
        exact: true,
      },
      {
        component: "TransactionInformation",
        path: "/transaction",
        componentPath: "pages/Dashboard/TransactionInformation",
        name: "TransactionInformation",
        auth: false,
        exact: true,
      },
      {
        component: "RetailerManagement",
        path: "/retailer",
        componentPath: "pages/Dashboard/RetailerManagement",
        name: "RetailerManagement",
        auth: false,
        exact: true,
      },
    ],
  },
];

export default routers;
