import { authApi } from "../service/apiVariables";

// common login
export const login =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...authApi.loginApi, body })
          .then(({ message, access_token, data }) => {
            localStorage.setItem("authToken", access_token);
            localStorage.setItem(
              "rolePermission",
              JSON.stringify(data.rolePermission)
            );
            Toast({ type: "success", message, time: 3000 });
            resolve(data);
          })
          .catch(({ message }) => {
            reject(Toast({ type: "error", message, time: 2000 }));
          });
      });
    };

// forgot Password
export const forgotPassword =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...authApi.forgotPasswordApi, body })
          .then(({ data, message, status }) => {
            resolve(data);
            Toast({ type: "success", message, time: 3000 });
          })
          .catch(({ message }) => {
            reject(Toast({ type: "error", message, time: 2000 }));
          });
      });
    };

//reset Password
export const resetPassword =
  (body) =>
    (dispatch, getState, { api, Toast }) => {
      return new Promise((resolve, reject) => {
        api({ ...authApi.resetPasswordApi, body, isForgotPassword: true })
          .then(({ message }) => {
            resolve();
            Toast({ type: "success", message, time: 3000 });
          })
          .catch(({ message }) => {
            reject(Toast({ type: "error", message, time: 2000 }));
          });
      });
    };
