import React from "react";
import "./style.scss";

export const NormalCheckbox = ({
  className = "custom-checkbox mb-0",
  label = "",
  value = "",
  name = "",
  onChange,
  checked,
  disable = false,
}) => {
  return (
    <label className={className}>
      <input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        disabled={disable}
        onChange={({ target: { name, checked: Checked } }) => {
          onChange && onChange({ target: { name, value: Checked } });
        }}
      />
      <span className="checkbox-tick border-radius-circle">
        <i className="icon-tick-transparent border-radius-circle"></i>
      </span>
      {label ? <span className="label-txt fs-14 pl-2">{label}</span> : ""}
    </label>
  );
};
