import React, { useState } from "react";
import { forgotPassword } from "action/AuthAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers";
import validate from "service/validation";
import "./style.scss";
import ErrorComponent from "component/common/ErrorComponent";
import emailImage from "assets/images/emailImage.png";
import { validationRules } from "./validate";

const ForgotPasswordClass = (props) => {
  const [emailId, setEmailId] = useState("");
  const [isButtonClicked, setisButtonClicked] = useState(false);
  const [error, setErrors] = useState({});
  const [checkMail, setCheckMail] = useState(false);

  //handle Change
  const handleChange = (e) => {
    e.persist();
    const { name, value } = e.target || e || {};

    const tempErrors = { ...error };

    tempErrors[name] = undefined;
    setEmailId(value);
    setErrors({ ...error, ...tempErrors });
  };

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());

    if (fieldInvalidList !== undefined) {
      const errors = {
        ...fieldInvalidList,
      };
      setErrors({ ...errors, ...fieldInvalidList });
    }

    return !fieldInvalidList;
  };

  // handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      emailId,
    };
    if (!validateFields(body)) return;
    setisButtonClicked(true);
    props
      .ForgotPasswordApiCall(body)
      .then(() => {
        setisButtonClicked(false);
        setEmailId("");
        setCheckMail(true);
      })
      .catch(() => {
        setisButtonClicked(false);
      });
  };

  return (
    <>
      {checkMail ? (
        <div className="forgot-password-area w-100">
          <div className="col-md-6 m-auto h-100">
            <div className="forgot-password py-md-5 p-3">
              <div className="row">
                <div className="col-8 mx-auto">
                  <form className="w-100">
                    <div className="d-flex justify-content-center m-auto">
                      <img src={emailImage} />
                    </div>
                    <p className="mb-3 fs-30 fw-700 text-center text-black-1a font-Roboto mt-4 pt-3 line-height-35">
                      Check Your Email
                    </p>
                    <p className="text-secondary line-height-19 fs-16 mb-4 pb-3 px-3 text-center">
                      We have sent a password recovery
                    </p>
                    <div className="col-12 d-flex justify-content-center">
                      <div className="col-9">
                        {/* <button type="submit" className="btn_login pt-3">
                          <a
                            target="_blank"
                            className="text-decoration-none text-white"
                            href="https://accounts.google.com/signin"
                          >
                            Open email
                          </a>
                        </button> */}
                      </div>
                    </div>
                    <div className="bottom_label_login d-flex justify-content-center mt-4 pt-5">
                      <div className="forget-pass ">
                        <p className="mb-0 fw-600 text-secondary">
                          Did not receive email?
                          <span
                            className="fs-16 fw-600 text-blue-2c mb-0 cursor-pointer pl-1 line-height-19"
                            onClick={() => setCheckMail(false)}
                          >
                            Try another email address
                          </span>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="forgot-password-area w-100">
          <div className="col-md-6 m-auto h-100">
            <div className="forgot-password py-md-5 p-3">
              <div className="row">
                <div className="col-8 mx-auto">
                  <form onSubmit={handleSubmit} className="w-100">
                    <h1 className="mb-3 text-center">Forgot Password?</h1>
                    <p className="description mb-4 pb-3 px-3">
                      Enter your email associated with your account and we’ll
                      send an email to reset your password
                    </p>
                    <label className="label_text_login pb-1">Email id</label>
                    <div className="mb-3 pb-1">
                      <div className="position-relative">
                        <input
                          className="input-area"
                          name="emailId"
                          value={emailId}
                          placeholder="Enter email"
                          onChange={handleChange}
                          maxLength={100}
                        />
                        <div className="input-group-addon right">
                          <i className={`cursor-pointer icon-mail fs-18`}></i>
                        </div>
                      </div>
                      {error.emailId && (
                        <ErrorComponent message={error.emailId[0]} />
                      )}
                    </div>

                    <button
                      type="submit"
                      className="btn_login mt-4 pt-3"
                      disabled={isButtonClicked}
                    >
                      Send confirmation
                    </button>
                    <div className="bottom_label_login pb-3 d-flex justify-content-center mt-4">
                      <div className="forget-pass ">
                        <p className="mb-0 fw-500">
                          Remember password?
                          <span
                            className="forget mb-0 cursor-pointer pl-1"
                            onClick={() => history.push("/auth/login")}
                          >
                            Login
                          </span>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ForgotPasswordApiCall: forgotPassword,
    },
    dispatch
  );
};

let component = ForgotPasswordClass;

export const ForgotPassword = connect(null, mapDispatchToProps)(component);
