import { fileManageMentApi } from "../service/apiVariables";
import { addQuery } from "service/helperFunctions";

//get File Management List
export const getFileManagementList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, fileManageMentApi.getAllList);
      api({ ...fileManageMentApi.getAllList })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//delete Uplaoded File
export const deleteUplaodedFile =
  (id) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      let { deleteUplaodedFileApi } = fileManageMentApi;
      deleteUplaodedFileApi.id = id;
      api({ ...fileManageMentApi.deleteUplaodedFileApi })
        .then(({ message }) => {
          Toast({ type: "success", message });
          resolve();
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//upload Product Api
export const uploadProduct =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...fileManageMentApi.uploadProductApi, body })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
