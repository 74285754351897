import React, { useEffect, useState } from "react";
import { login } from "action/AuthAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { history } from "service/helpers";
import validate from "service/validation";
import "./style.scss";
import image from "assets/images/imageLft_login.png";
import { NormalCheckbox } from "component/common/NormalCheckbox";
import ErrorComponent from "component/common/ErrorComponent";
import Cookies from "universal-cookie";
import { logout } from "service/utilities";
import { validationRules } from "./validate";
import { Navbar } from "component/common/navbar";
// import { Navbar } from "component/common/Navbar";

const cookies = new Cookies();

const LoginClass = (props) => {
  const [logindetails, setLoginDetails] = useState({
    emailId: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isButtonClicked, setisButtonClicked] = useState(false);
  const [error, setErrors] = useState({});

  useEffect(() => {
    setLoginDetails({
      emailId: cookies.get("emailId") || "",
      password: cookies.get("password") || "",
    });
    setRememberMe(
      cookies.get("emailId") && cookies.get("password") ? true : false
    );
  }, []);

  //handle Change
  const handleChange = (e) => {
    e.persist();
    const { name, value } = e.target || e || {};

    const tempErrors = { ...error };

    tempErrors[name] = undefined;
    setLoginDetails({ ...logindetails, [name]: value });
    setErrors({ ...error, ...tempErrors });
  };

  //validate Fields
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());

    if (fieldInvalidList !== undefined) {
      const errors = {
        ...fieldInvalidList,
      };
      setErrors({ ...errors, ...fieldInvalidList });
    }

    return !fieldInvalidList;
  };

  //handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let body = {
      ...logindetails,
    };
    if (!validateFields(body)) return;
    setisButtonClicked(true);
    props
      .loginApiCall(body)
      .then((data) => {
        if (rememberMe) {
          let date = new Date();
          date.setTime(date.getTime() + 48 * 60 * 60 * 1000);
          let dateString = date.toGMTString();
          cookies.set("emailId", logindetails.emailId, [
            { path: "/" },
            { expires: dateString },
          ]);
          cookies.set("password", logindetails.password, [
            { path: "/" },
            { expires: dateString },
          ]);
        } else {
          cookies.remove("emailId");
          cookies.remove("password");
        }
        setisButtonClicked(false);
        let access = data.rolePermission;
        let {
          userAccess = {},
          retailerAccess = {},
          fileManagementAccess = {},
          productManagementAccess = {},
          transactionAccess = {},
        } = access || {};

        //route change based on access
        if (userAccess && userAccess.view) {
          history.push("/dashboard/userManagement");
        } else if (retailerAccess && retailerAccess.view) {
          history.push("/dashboard/retailer");
        } else if (fileManagementAccess && fileManagementAccess.view) {
          history.push("/dashboard/fileManagement");
        } else if (productManagementAccess && productManagementAccess.view) {
          history.push("/dashboard/fileUpload");
        } else if (transactionAccess && transactionAccess.view) {
          history.push("/dashboard/transaction");
        } else {
          logout();
        }
      })
      .catch(() => {
        setisButtonClicked(false);
      });
  };

  return (
    <div className="w-100">
      <Navbar />
      <div className="background_login w-100">
        <div className="container">
          <div className="row">
            <div className="col-md-9 mx-auto">
              <div className="row">
                <div className="col-md-6 px-md-0">
                  <div className="imageLft_login">
                    <img src={image} alt="" />
                  </div>
                </div>
                <div className="col-md-6 px-md-0">
                  <div className="container_login">
                    <form onSubmit={handleSubmit} className="w-100">
                      <div>
                        <h1 className="mb-4">Login to your account</h1>
                        <label className="label_text_login pb-1">
                          Registered Email ID
                        </label>
                        <div className="mb-3 pb-1">
                          <div className="position-relative">
                            <input
                              className="input-area"
                              name="emailId"
                              value={logindetails.emailId}
                              placeholder="Enter email ..."
                              onChange={handleChange}
                              maxLength={100}
                            />
                            <div className="input-group-addon right">
                              <i
                                className={`cursor-pointer icon-mail fs-18`}
                              ></i>
                            </div>
                          </div>
                          {error.emailId && (
                            <ErrorComponent message={error.emailId[0]} />
                          )}
                        </div>
                        <label className="label_text_login pb-1">
                          Password
                        </label>
                        <div className="mb-3 pb-1">
                          <div className="position-relative">
                            <input
                              name="password"
                              type={passwordVisible ? "text" : "password"}
                              className="input-area"
                              value={logindetails.password}
                              onChange={handleChange}
                              placeholder="&#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679; &#9679;"
                            />
                            <div className="input-group-addon right">
                              <i
                                onClick={() =>
                                  setPasswordVisible(!passwordVisible)
                                }
                                className={`cursor-pointer icon-${
                                  passwordVisible ? "eye" : "eye-hide"
                                } fs-18`}
                              ></i>
                            </div>
                          </div>
                          {error.password && (
                            <ErrorComponent message={error.password[0]} />
                          )}
                        </div>
                        <div className="bottom_label_login pb-3">
                          <NormalCheckbox
                            label="Remember me"
                            value={rememberMe}
                            checked={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                          />
                          <div
                            className="forget-pass cursor-pointer"
                            onClick={() =>
                              history.push("/auth/forgot-password")
                            }
                          >
                            <p className="forget mb-0">Forgot password?</p>
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="btn_login"
                          disabled={isButtonClicked}
                        >
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginApiCall: login,
    },
    dispatch
  );
};

let component = LoginClass;

export const LoginComp = connect(null, mapDispatchToProps)(component);
