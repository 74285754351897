import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { NormalButton } from "component/common";
import downloadSuccessIcon from "assets/svg/Download-image.svg";
import uploadSuccessIcon from "assets/svg/upload-image.svg";
import { csvErrorHeaders } from "service/helpers/Constants";
import "./style.scss";
import { CSVLink } from "react-csv";
import moment from "moment";

export class DowloadOrUploadSuccessClass extends Component {
  render() {
    let {
      className = "modal-dialog-centered downlaod-or-upload-popup",
      isOpen = false,
      toggle,
      handleChange,
      type = "",
      title,
      fileUploadResponse = {},
    } = this.props;

    let {
      error = [],
      RecordsInserted = 0,
      RecordsUpdated = 0,
    } = fileUploadResponse || {};
    return (
      <Modal isOpen={isOpen} toggle={toggle} className={className} size="md">
        <ModalBody className="p-0">
          <div className="">
            <p
              className={`${type === "download" ? "text-blue-2c" : "text-success-00"
                } fs-22 line-height-22 fw-700 text-center mb-5`}
            >
              {title}
            </p>
            <div className="d-flex flex-column align-items-center justify-content-center pb-4">
              <img
                src={
                  type === "download" ? downloadSuccessIcon : uploadSuccessIcon
                }
                alt="icon"
              />
              {type === "upload" ? (
                <>
                  <p className="mb-1 fs-16 text-center pt-3">
                    Records Inserted : {RecordsInserted}
                  </p>
                  <p className="mb-1 fs-16 text-center">
                    Records Updated : {RecordsUpdated}
                  </p>
                  {error.length !== 0 ? (
                    <p className="mb-1 fs-16 text-center">
                      Errors in Uploaded file : {error.length}
                    </p>
                  ) : null}
                  {error.length !== 0 ? (
                    <CSVLink
                      data={fileUploadResponse.error}
                      headers={csvErrorHeaders}
                      filename={`Error file (${moment().format(
                        "DD/MM/YYYY hh:mm"
                      )}).csv`}
                      className="text-decoration-underline text-danger fs-16"
                    >
                      Click here to download error detail File
                    </CSVLink>
                  ) : (
                    ""
                  )}
                </>
              ) : null}
            </div>
            <div className="d-flex justify-content-end mt-4 py-3 w-100">
              <div className="mx-auto d-flex">
                <NormalButton
                  label="Close"
                  isPrimay={type === "download" ? true : false}
                  successBg={type === "upload" ? true : false}
                  onClick={() => handleChange()}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

let component = DowloadOrUploadSuccessClass;

export const DowloadOrUploadSuccess = connect(null, null)(component);
