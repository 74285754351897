import React, { useEffect } from "react";
import FileManage from "../../component/Dashboard/FileManagement/index";
import { getAccessdata } from "service/helperFunctions";
import { logout } from "service/utilities";

export function FileManagement() {
  let access = getAccessdata("file-management");
  useEffect(() => {
    if (!access.viewAccess) {
      logout();
    }
  }, []);
  return <FileManage access={access} />;
}
