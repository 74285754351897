import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 286;

export const mainLayoutStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      display: "none",
    },
    display: "block",
  },
  nav: {
    paddingTop: "20px",
    "& .MuiListItemIcon-root": {
      minWidth: "41px",
      marginLeft: "10px",
    },
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "40px",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#FBFBFB",
    border: "1px solid #D2D2D2",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: "2.3rem",
    },
    paddingTop: "5rem",
  },
}));

//File Management Header
export const FileManagementHeader = [
  {
    label: "S. No",
    // isSort: true,
  },
  {
    label: "Date",
    // isSort: true,
  },
  {
    label: "Time",
    // isSort: true,
  },
  {
    label: "Retailer name",
    // isSort: true,
  },
  {
    label: "File name",
    // isSort: true,
  },
  {
    label: "Download",
    // isSort: false,
  },
  {
    label: "",
    // isSort: false,
  },
];

//user Management Header
export const userManagementHeader = [
  {
    label: "S. No",
  },
  {
    label: "Name",
  },
  {
    label: "Registered Email ID",
  },
  {
    label: "Date",
  },
  {
    label: "Time",
  },
  {
    label: "Account Status",
  },
  {
    label: "Enabled/Disabled",
  },
  {
    label: "",
  },
];

//file Upload Manage
export const fileUploadManage = [
  {
    label: "File Code",
    // isSort: true,
  },
  {
    label: "Component",
    // isSort: true,
  },
  {
    label: "Category",
    // isSort: true,
  },
  {
    label: "Material / Price",
    // isSort: true,
  },
  {
    label: "Component",
    // isSort: false,
  },
  {
    label: "Material / Price",
    // isSort: false,
  },
  {
    label: "Component",
    // isSort: false,
  },
  {
    label: "Material / Price",
    // isSort: false,
  },
  {
    label: "",
    // isSort: false,
  },
];

//transaction Information Table Header
export const transactionInformationTableHeader = [
  {
    label: "S. No",
  },
  {
    label: "Date",
  },
  {
    label: "Time",
  },
  {
    label: "Product name",
  },
  {
    label: "Retailer name",
  },
  {
    label: "Tranaction type",
  },
];

//csv Error Headers
export const csvErrorHeaders = [
  { label: "Excel Line No", key: "ExcelLineNo" },
  { label: "Excel Tab", key: "ExcelTab" },
  { label: "Error", key: "error" },
];

//retailer Management Header
export const retailerManagementHeader = [
  {
    label: "S. No",
  },
  {
    label: "Retailer",
  },
  {
    label: "Registered Email ID",
  },
  {
    label: "Date",
  },
  {
    label: "Time",
  },
  {
    label: "Subscription key",
  },
  {
    label: "Account Status",
  },
  {
    label: "Enabled/Disabled",
  },
  {
    label: "",
  },
];
