import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./style.scss";
export const CustomPhoneInput = ({
  onChange,
  label,
  value,
  disabled = false,
}) => {
  return (
    <div className="custom-phone-input">
      {label !== "" ? (
        <div>
          <label className="font-weight-normal mb-1">{label}</label>
        </div>
      ) : null}
      <PhoneInput
        country={"in"}
        value={value}
        enableSearch={true}
        countryCodeEditable={false}
        onChange={onChange}
        specialLabel={""}
        disabled={disabled}
      />
    </div>
  );
};
