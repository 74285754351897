import React, { Component } from "react";
// Redux Connection
import { connect } from "react-redux";

import { ResetPassword } from "component/auth/login/index";
import "../../component/auth/button.scss";
export class ResetpasswordClass extends Component {
  render() {
    return (
      <form>
        <div className="shadow-lg rounded d-flex align-items-center flex-column">
          <ResetPassword
            token={new URLSearchParams(this.props.location.search).get("token")}
          />
        </div>
      </form>
    );
  }
}
export const Resetpassword = connect(null, null)(ResetpasswordClass);
