import RetailerManage from "component/Dashboard/RetailerManagement";
import React, { useEffect } from "react";
import { getAccessdata } from "service/helperFunctions";
import { logout } from "service/utilities";

export function RetailerManagement() {
  let access = getAccessdata("retailer-management");
  useEffect(() => {
    if (!access.viewAccess) {
      logout();
    }
  }, []);
  return <RetailerManage access={access} />;
}
