import UserManagement from "component/Dashboard/UserManagement/index";
import React, { useEffect } from "react";
import { getAccessdata } from "service/helperFunctions";
import { logout } from "service/utilities";

export function Usermanagement() {
  let access = getAccessdata("user-management");
  useEffect(() => {
    if (!access.viewAccess) {
      logout();
    }
  }, []);
  return <UserManagement access={access} />;
}
