import React, { useRef, useEffect, useCallback, useState } from "react";
import Select, { components } from "react-select";
import { getRetailerList } from "action/UserMangementAct";
import "./index.scss";
import hash from "assets/images/hash.png";
import close from "assets/images/close.svg";
import { Modal, ModalBody } from "reactstrap";
import { Toast } from "service/toast";
import { uploadProduct } from "action/FileManagementAct";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const DragandDropClass = ({
  showDargdrop,
  setShowDargdrop,
  uploadProductApiCall,
  fetchFileList,
  handleSuccessPopup,
  responseData,
  getRetailerListApi,
}) => {
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Browse file");
  const [filedrop, setFiledrop] = useState("Drag and drop a file to upload");
  const [or, setOr] = useState("or");
  const [header, setheader] = useState("Import Files");
  const [uploadingButton, setUploadingButton] = useState(true);
  const [percentageButton, setpercentageButton] = useState(true);
  const [Remove, setRemove] = useState(null);
  const [retailerList, setRetailerList] = useState([]);
  const [retailerId, setRetailerId] = useState("");

  useEffect(() => {
    if (showDargdrop) {
      getRetailerListApiFunc();
    }
  }, [showDargdrop]);

  useEffect(() => {
    getRetailerListApiFunc();
  }, []);

  //get Retailer List Api Func
  const getRetailerListApiFunc = () => {
    getRetailerListApi().then(({ data }) => {
      let resultData = [];
      if (data) {
        data.usersList.map((list) => {
          resultData.push({ value: list._id, label: list.name });
        });
        if (resultData.length === data.usersList.length) {
          setRetailerList([...resultData]);
        }
      }
    });
  };

  //drag Over
  const dragOver = (e) => {
    e.preventDefault();
  };

  //drag Enter
  const dragEnter = (e) => {
    e.preventDefault();
  };

  //drag Leave
  const dragLeave = (e) => {
    e.preventDefault();
  };

  //fileDrop
  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0].name;
    const file = e.dataTransfer.files[0];
    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(file);
      setFiledrop(<span className="fileName">{files}</span>);
      setOr("");
      setFilename("");
      setheader("");
      setUploadingButton(false);
      setRemove("Remove file");
    } else {
      Toast({ type: "error", message: "Please Upload xlsx file" });
    }
  };

  //onChange
  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename("");
    setFiledrop(<span className="fileName">{e.target.files[0].name}</span>);
    setOr("");
    setheader("");
    setUploadingButton(false);
    setRemove("Remove file");
  };

  //handle Remove
  const handleRemove = (e) => {
    e.preventDefault();
    setFiledrop("Drag and drop a file to upload");
    setFilename("Browse file");
    setUploadingButton(true);
    setpercentageButton(true);
    setOr("or");
    setRemove(null);
  };

  const dragRef = useRef();

  //close Drag
  const closeDrag = (e) => {
    if (dragRef.current === e.target) {
      setShowDargdrop(false);
      setFiledrop("Drag and drop a file to upload");
      setFilename("Browse file");
      setUploadingButton(true);
      setpercentageButton(true);
      setOr("or");
      setRemove("");
    }
  };

  //key Press
  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showDargdrop) {
        setShowDargdrop(false);
        setFiledrop("Drag and drop a file to upload");
        setFilename("Browse file");
        setUploadingButton(true);
        setpercentageButton(true);
        setOr("or");
        setRemove("");
      }
    },
    [setShowDargdrop, showDargdrop]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  //upload File
  const uploadFile = (e) => {
    e.preventDefault();
    if (retailerId !== "") {
      setRemove("");
      setpercentageButton(false);
      let formData = new FormData();
      formData.append("file", file);
      formData.append("retailerId", retailerId);
      uploadProductApiCall(formData)
        .then((res) => {
          if (res) {
            responseData(res);
            setShowDargdrop(false);
            handleSuccessPopup();
            setFiledrop("Drag and drop a file to upload");
            setFilename("Browse file");
            setUploadingButton(true);
            setpercentageButton(true);
            setOr("or");
            setRemove("");
            setRetailerId("");
            fetchFileList();
          }
        })
        .catch(() => {
          setpercentageButton(false);
          setUploadingButton(false);
        });
    } else {
      Toast({ type: "error", message: "Please select Retailer" });
    }
  };

  //Close Button
  const CloseButton = () => {
    setShowDargdrop((prev) => !prev);
    setFiledrop("Drag and drop a file to upload");
    setFilename("Browse file");
    setUploadingButton(true);
    setpercentageButton(true);
    setOr("or");
    setRemove("");
    setRetailerId("");
  };
  const customStyles = {
    placeholder: (base) => ({
      ...base,
      fontSize: "1em",
      color: "#2D3748",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 0,
      height: "24px",
      width: "24px",
      color: "black",
    }),
    singleValue: (base) => ({
      ...base,
      color: "#2D3748",
    }),
    control: (base) => ({
      ...base,
      borderRadius: "4px",
      border: "none",
      boxShadow: ` 0 0 !important`,
      height: "40px",
      outline: "0 ",
      background: "white",
    }),
    menu: (base) => ({
      ...base,
      textAlign: "left",
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className={`icon-arrow-down fs-24`}></i>
        </components.DropdownIndicator>
      )
    );
  };

  const handleChange = ({ value }) => {
    setRetailerId(value);
  };
  return (
    <Modal
      isOpen={showDargdrop}
      className={"modal-dialog-centered upload-file"}
    >
      <ModalBody>
        <div ref={dragRef} onClick={closeDrag}>
          <form onSubmit={uploadFile}>
            <div className="big_container">
              <div
                className="CloseDragdropButton"
                onClick={() => {
                  CloseButton();
                }}
              >
                <img src={close} alt="close" />
              </div>

              <div className="container-header">{header}</div>
              <div
                className="drop-container mb-1"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
              >
                <div className="drop-message">
                  <div className="upload-icon">
                    <div className="inner-icon-part">
                      <div className="pb-3">
                        <img src={hash} alt="" />
                      </div>
                    </div>
                  </div>

                  <span className="drag-text">
                    {filedrop} <br />
                    {or}
                    <br />
                  </span>

                  <input
                    type="file"
                    id="file"
                    onChange={onChange}
                    onClick={(event) => {
                      event.currentTarget.value = null;
                    }}
                    accept=".xlsx"
                  />
                  <label htmlFor="file" className="browse-file cursor-pointer">
                    {filename}
                  </label>
                  {Remove ? (
                    <div className="cancel-area" onClick={handleRemove}>
                      {Remove}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {Remove === null ? (
                  <div className="text-secondary fs-12 line-height-14 text-center mb-0 mt-4">
                    Maximum 5gb upload.In excel format
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="dropdown-area mb-4">
                <Select
                  className={"select-form-control w-100"}
                  classNamePrefix="Select"
                  isClearable={false}
                  isSearchable={true}
                  name={"retailerId"}
                  options={retailerList}
                  onChange={handleChange}
                  isMulti={false}
                  placeholder={"Select Retailer"}
                  styles={customStyles}
                  value={
                    !!retailerList && retailerList.length > 0
                      ? retailerList.find((data) => data.value === retailerId)
                        ? retailerList.find((data) => data.value === retailerId)
                        : null
                      : null
                  }
                  components={{ DropdownIndicator }}
                />
              </div>

              {percentageButton ? (
                <div className="w-100">
                  {uploadingButton ? (
                    <div className="label">Upload file</div>
                  ) : (
                    <input
                      type="submit"
                      value="Upload file"
                      className="label_active"
                    />
                  )}
                </div>
              ) : (
                <div className="w-100">
                  <div className="label">Uploading...</div>
                </div>
              )}
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapdispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      uploadProductApiCall: uploadProduct,
      getRetailerListApi: getRetailerList,
    },
    dispatch
  );
};

export const DragandDrop = connect(null, mapdispatchToProps)(DragandDropClass);
