import { userManagementApi } from "../service/apiVariables";
import { addQuery } from "service/helperFunctions";

//get user List
export const getUserList =
  (query) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      addQuery(query, userManagementApi.getUserList);
      api({ ...userManagementApi.getUserList })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//delete User File
export const deleteUser =
  (emailId) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      userManagementApi.deleteUserApi.emailId = emailId;
      api({ ...userManagementApi.deleteUserApi })
        .then(({ message }) => {
          Toast({ type: "success", message });
          resolve();
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Roles List
export const getRolesList =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...userManagementApi.getRolesList })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Retailer List
export const getRetailerList =
  () =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...userManagementApi.getRetailerList })
        .then((data) => {
          resolve(data);
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//get Retailer List
export const addUser =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...userManagementApi.addUser, body })
        .then(({ message }) => {
          Toast({ type: "success", message });
          resolve();
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };

//update User
export const updateUser =
  (body) =>
  (dispatch, getState, { api, Toast }) => {
    return new Promise((resolve, reject) => {
      api({ ...userManagementApi.updateUser, body })
        .then(({ message }) => {
          Toast({ type: "success", message });
          resolve();
        })
        .catch(({ message }) => {
          reject(Toast({ type: "error", message }));
        });
    });
  };
