import React from "react";
import PropTypes from "prop-types";
import "./table.scss";
import Arrow from "../../../assets/icons/arrow.png";
import ReactPaginate from "react-paginate";

export function TableWrapper(props) {

  const option = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 50, label: "50" },
  ]

  let {
    headerDetails,
    children,
    className = "",
    overFlow = true,
    paginationShow = true,
    paginationConfig,
    paginationChange,
    handlePageSize,
    uploadTable,
    isUploadPage = false,
    pageNumber = "",
  } = props;

  //handle Page Change
  const handlePageChange = (pageNumber) => {
    paginationChange(pageNumber);
  };

  return (
    <>
      <div className="maintable table-container">
        <div
          className={`maintable-content ${overFlow ? "table-responsive" : ""
            } ${className}`}
        >
          {isUploadPage ? (
            <div className="upLoadfileTopHeader">
              <div className="baseComponent">Base Component</div>
              <div className="groupedAddOn">GroupedAddOn</div>
              <div className="AddOnComponent">AddOnComponent</div>
            </div>
          ) : null}
          <table className={`${uploadTable} table`}>
            <thead>
              {isUploadPage ? (
                <>
                  <tr>
                    {headerDetails.map(
                      (
                        {
                          label,
                          className,
                          divClass = "",
                          sortKey = "",
                          element,
                          isSort = false,
                        },
                        index
                      ) => {
                        return (
                          <>
                            <th className="uploadFileTable" key={index}>
                              <div
                                className={`d-flex align-items-center justify-content-center text-center ${sortKey && "cursor-pointer"
                                  } ${divClass}`}
                              >
                                {label}
                                {element && element()}
                                {isSort ? (
                                  <img
                                    src={Arrow}
                                    alt="Arrow"
                                    className="pl-1 cursor-pointer"
                                  ></img>
                                ) : null}
                              </div>
                            </th>
                          </>
                        );
                      }
                    )}
                  </tr>
                </>
              ) : (
                <tr>
                  {headerDetails.map(
                    (
                      {
                        label,
                        className,
                        divClass = "",
                        sortKey = "",
                        element,
                        isSort = false,
                      },
                      index
                    ) => {
                      return (
                        <th className={className} key={index}>
                          <div
                            className={`d-flex align-items-center justify-content-center text-center ${sortKey && "cursor-pointer"
                              } ${divClass}`}
                          >
                            {label}
                            {element && element()}
                            {isSort ? (
                              <img
                                src={Arrow}
                                alt="Arrow"
                                className="pl-1 cursor-pointer"
                              ></img>
                            ) : null}
                          </div>
                        </th>
                      );
                    }
                  )}
                </tr>
              )}
            </thead>
            <tbody>{children}</tbody>
          </table>
        </div>
      </div>
      {paginationConfig && (
        <div className="mt-3">
          {paginationShow ? (
            <div className="div-pagination pt-2">
              <div className="show-product">
                <span>Show</span>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  onChange={(e) => {
                    handlePageSize(e.target.value);
                  }}
                >
                  {option.length !== 0 ? (
                    option.map((option, index) => (
                      <option value={option.value} key={index}>
                        {option.label}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No Options
                    </option>
                  )}
                </select>
                <span>products</span>
              </div>
              <ReactPaginate
                disableInitialCallback={true}
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={<span>......</span>}
                breakClassName={"break-me"}
                pageCount={paginationConfig.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(e) => {
                  handlePageChange(e.selected + 1);
                }}
                disableInitialCallback={true}
                containerClassName={"pagination ml-auto"}
                subContainerClassName={"pages pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                previousClassName={"prev"}
                nextClassName={"next"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                forcePage={paginationConfig.page - 1}
              />
            </div>
          ) : (
            <div className="div-pagination ml-auto">
              <ReactPaginate
                disableInitialCallback={true}
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={<span>......</span>}
                breakClassName={"break-me"}
                pageCount={paginationConfig.totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={(e) => {
                  handlePageChange(e.selected + 1);
                }}
                disableInitialCallback={true}
                containerClassName={"pagination ml-auto"}
                subContainerClassName={"pages pagination"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                activeClassName={"active"}
                previousClassName={"prev"}
                nextClassName={"next"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
              // forcePage={pageNumber}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

TableWrapper.propTypes = {
  placeHolder: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  isEmpty: PropTypes.bool,
  headerDetails: PropTypes.array.isRequired,
  pageMeta: PropTypes.object,
};
