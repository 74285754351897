import { generateQuery } from "../service/helperFunctions";

//auth Api
export const authApi = {
  loginApi: {
    api: "adminLogin",
    method: "post",
    baseURL: "auth",
  },
  forgotPasswordApi: {
    api: "adminForgetPassword",
    method: "post",
    baseURL: "auth",
  },
  resetPasswordApi: {
    api: "adminResetPassword",
    method: "put",
    baseURL: "auth",
  },
};

//file ManageMent Api
export const fileManageMentApi = {
  getAllList: {
    url: "getFile",
    method: "get",
    baseURL: "auth",
    query: {
      pageNo: null,
      size: null,
      search: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteUplaodedFileApi: {
    url: "getFile/",
    method: "delete",
    baseURL: "auth",
    id: null,
    get api() {
      return this.url + this.id;
    },
  },
  uploadProductApi: {
    api: "uploadProduct",
    method: "post",
    baseURL: "auth",
  },
};

//user Management Api
export const userManagementApi = {
  getUserList: {
    url: "getall/user",
    method: "get",
    baseURL: "auth",
    query: {
      pageNo: null,
      search: null,
      pageLimit: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteUserApi: {
    url: "user/",
    method: "delete",
    baseURL: "auth",
    emailId: null,
    get api() {
      return this.url + this.emailId;
    },
  },
  getRolesList: {
    api: "roles",
    method: "get",
    baseURL: "auth",
  },
  getRetailerList: {
    api: "get/all/retailer",
    method: "get",
    baseURL: "auth",
  },
  addUser: {
    api: "create/user",
    method: "post",
    baseURL: "auth",
  },
  updateUser: {
    api: "update/user",
    method: "put",
    baseURL: "auth",
  },
};

//product Api
export const productApi = {
  getAllProduct: {
    url: "products",
    method: "get",
    baseURL: "auth",
    query: {
      pageNo: null,
      size: null,
      search: null,
      retailerId: null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteProduct: {
    url: "productDelete/",
    method: "delete",
    baseURL: "auth",
    id: null,
    get api() {
      return this.url + this.id;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

//transaction Information Api
export const transactionInformationApi = {
  getAllInfo: {
    url: "transaction",
    method: "get",
    baseURL: "auth",
    query: {
      pageNo: null,
      size: null,
      search: null,
      sort: null,
      retailerId: null
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
};

//retailer Management Api
export const retailerManagementApi = {
  getAllRetailer: {
    url: "getall/retailer",
    method: "get",
    baseURL: "auth",
    query: {
      pageNo: null,
      pageLimit: null,
      search: null,
      // sortBy: null,
      // isDesc: null,
    },
    get api() {
      return this.url + generateQuery(this.query);
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  deleteRetailer: {
    url: "retailer/",
    method: "delete",
    baseURL: "auth",
    emailId: null,
    get api() {
      return this.url + this.emailId;
    },
    set addQuery({ key, payload }) {
      this.query[key] = payload;
    },
  },
  addNewRetailer: {
    api: "create/retailer",
    method: "post",
    baseURL: "auth",
  },
  updateRetailer: {
    api: "update/retailer",
    method: "put",
    baseURL: "auth",
  },
};
