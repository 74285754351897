import { transactionInformationApi } from "../service/apiVariables";
import { addQuery } from "service/helperFunctions";

// get trans info api
export const transactionInformationApiCall =
    (query) =>
        (dispatch, getState, { api, Toast }) => {
            return new Promise((resolve, reject) => {
                addQuery(query, transactionInformationApi.getAllInfo);
                api({ ...transactionInformationApi.getAllInfo })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch(({ }) => {
                        reject(Toast({ type: "error", message: "Error" }));
                    });
            });
        };