import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { NormalButton } from "component/common";
import "./style.scss";

export class DeletePopupClass extends Component {
  render() {
    let {
      className = "modal-dialog-centered delete-popup",
      isOpen = false,
      toggle,
      handleChange,
      isPopupLoading = false,
      title = "",
      selectedFileName = "",
      type = "",
      deleteIcon,
    } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={toggle} className={className} size="md">
        <ModalBody className="p-0">
          <div className="">
            {type !== "" ? (
              <p className="text-danger fs-20 line-height-22 fw-700 text-center">
                Delete {type}
              </p>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-center pb-4">
              <img src={deleteIcon} alt="deleteIcon" />
            </div>
            <p className="text-secondary fs-16 line-height-19 text-center mb-4">
              {title}
            </p>
            {selectedFileName !== "" ? (
              <p className="text-primaryColor fs-16 line-height-19 text-center">
                {selectedFileName}
              </p>
            ) : null}
            <div className="d-flex justify-content-end mt-4 py-3 w-100">
              <div className="mx-auto d-flex">
                <NormalButton
                  label="Cancel"
                  outline
                  onClick={() => toggle()}
                  className=""
                  disabled={isPopupLoading}
                />
                <NormalButton
                  label="Confirm"
                  danger={true}
                  onClick={() => handleChange()}
                  className="ml-4"
                  disabled={isPopupLoading}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

let component = DeletePopupClass;

export const DeletePopup = connect(null, null)(component);
