import React, { useEffect, useState } from "react";
import { CommonPageLoader, TableWrapper, NormalSearch } from "component/common";
import { FileManagementHeader } from "service/helpers/Constants";
import { NormalButton } from "component/common/NormalButton/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SearchIcon from "@material-ui/icons/Search";
import {
  getFileManagementList,
  deleteUplaodedFile,
} from "action/FileManagementAct";
import Download from "assets/icons/download.png";
import { DragandDrop } from "component/common/DragDrop";
import moment from "moment";
import { downloadBaseUrl } from "service/helperFunctions";
import { DeletePopup } from "component/common/Modal/DeletePopup";
import { DowloadOrUploadSuccess } from "component/common/Modal/DowloadOrUploadSuccess";
import deleteIcon from "assets/images/delete.svg";

function FileManage({
  getFileManagementListApi,
  deleteUplaodedFileApiCall,
  access: {
    uploadAccess = false,
    deleteAccess = false,
    downloadAccess = false,
  },
}) {
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [paginationConfig, setPaginationConfig] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [showDargdrop, setShowDargdrop] = useState(false);
  const [isPopupLoading, setIsPopupLoading] = useState(false);
  const [isDownloadPopupOpen, setIsDownLoadPopup] = useState(false);
  const [isUploadSuccessPopup, setIsUploadSuccessPopup] = useState(false);
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  const [pageLimit, setPageLimit] = useState(10);
  const [headerList, setHeaderList] = useState(FileManagementHeader);

  useEffect(() => {
    fetchFileList();
  }, [page, searchValue, pageLimit]);

  useEffect(() => {
    if (!deleteAccess) {
      headerList.splice(headerList.length - 1, 1);
      setHeaderList([...headerList]);
    }
    if (!downloadAccess) {
      headerList.splice(headerList.length - 1, 1);
      setHeaderList([...headerList]);
    }
  }, []);

  //fetch Files List
  const fetchFileList = () => {
    setLoading(true);
    let query = {
      size: pageLimit,
      pageNo: page,
      search: searchValue,
    };
    getFileManagementListApi(query)
      .then(({ data, pageMeta }) => {
        if (data) {
          setPaginationConfig(pageMeta);
          setFileList(data);
        } else {
          setFileList([]);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //handle Delete File
  const handleDeleteFile = () => {
    if (deleteId) {
      setIsPopupLoading(true);
      deleteUplaodedFileApiCall(deleteId)
        .then(() => {
          setIsOpen(false);
          fetchFileList();
          setIsPopupLoading(false);
        })
        .catch(() => {
          setIsPopupLoading(false);
        });
    }
  };

  //handle Toggle for delete popup
  const handleToggle = (id, filename) => {
    if (id) {
      setDeleteId(id);
      setSelectedFileName(filename);
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setSelectedFileName("");
      setDeleteId(null);
    }
  };

  //handle Download
  const handleDownload = (file) => {
    var a = window.document.createElement("a");
    a.href = downloadBaseUrl + file;
    a.download = `${file}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setIsDownLoadPopup(true);
  };

  //upload Response Data
  const uploadResponseData = (uploadResData) => {
    setFileUploadResponse(uploadResData);
  };

  return (
    <div className="row align-items-start file-management">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <NormalSearch
            placeholder={"Search"}
            value={searchValue}
            name={"searchValue"}
            onChange={(e) => setSearchValue(e.target.value)}
            icon={<SearchIcon />}
          />
          {uploadAccess ? (
            <NormalButton
              label="Upload New File"
              normal
              className="ml-auto"
              onClick={() => setShowDargdrop(!showDargdrop)}
            />
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <TableWrapper
          headerDetails={headerList}
          pageMeta={true}
          className="time-off"
          paginationShow={true}
          paginationConfig={paginationConfig}
          handlePageChange={setPage}
          handlePageSize={(size) => {
            setPage(1);
            setPageLimit(size);
          }}
          paginationChange={setPage}
        >
          {!loading ? (
            <>
              {fileList.length > 0 ? (
                fileList.map((item, index) => {
                  let { createdAt, _id, fileName, file, retailerId } = item;
                  return (
                    <tr className="my-2" key={index}>
                      <td className="text-center">
                        {(page - 1) * 10 + index + 1}
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center">
                          {createdAt
                            ? moment(createdAt).format("MM/DD/YYYY")
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center">
                          {createdAt ? moment(createdAt).format("H:mm A") : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center">
                          {retailerId ? retailerId.name : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center">
                          {fileName}
                        </div>
                      </td>
                      {downloadAccess ? (
                        <td>
                          <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center">
                            <p
                              onClick={() => handleDownload(file)}
                              className="cursor-pointer mb-0"
                            >
                              <img src={Download} alt="Download"></img>
                            </p>
                          </div>
                        </td>
                      ) : null}
                      {deleteAccess ? (
                        <td>
                          <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center">
                            <NormalButton
                              label="Delete"
                              outlineDanger={true}
                              onClick={() => handleToggle(_id, fileName)}
                            />
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  );
                })
              ) : (
                <td colSpan="6" className="text-center">
                  No data
                </td>
              )}
            </>
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                <CommonPageLoader />
              </td>
            </tr>
          )}
        </TableWrapper>
      </div>
      {/* Drag and Drop popup */}
      <DragandDrop
        showDargdrop={showDargdrop}
        setShowDargdrop={() => {
          setShowDargdrop(!showDargdrop);
        }}
        fetchFileList={() => fetchFileList()}
        responseData={uploadResponseData}
        handleSuccessPopup={() => setIsUploadSuccessPopup(true)}
      />

      {/* Dowload success popup  */}
      <DowloadOrUploadSuccess
        isOpen={isDownloadPopupOpen}
        toggle={() => setIsDownLoadPopup(!isDownloadPopupOpen)}
        title="Downloded Successfully"
        type="download"
        handleChange={() => {
          setIsDownLoadPopup(!isDownloadPopupOpen);
          fetchFileList();
        }}
      />

      {/* Upload success popup  */}
      <DowloadOrUploadSuccess
        isOpen={isUploadSuccessPopup}
        toggle={() => setIsUploadSuccessPopup(!isUploadSuccessPopup)}
        title="Upload Successful"
        type="upload"
        handleChange={() => {
          setIsUploadSuccessPopup(false);
          fetchFileList();
        }}
        fileUploadResponse={fileUploadResponse}
      />

      {/* Delete popup  */}
      <DeletePopup
        isOpen={isOpen}
        title="Are you sure you want to delete"
        selectedFileName={selectedFileName}
        toggle={() => handleToggle()}
        deleteIcon={deleteIcon}
        type="File"
        handleChange={handleDeleteFile}
        isPopupLoading={isPopupLoading}
      />
    </div>
  );
}
const mapdispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFileManagementListApi: getFileManagementList,
      deleteUplaodedFileApiCall: deleteUplaodedFile,
    },
    dispatch
  );
};

export default connect(null, mapdispatchToProps)(FileManage);
