//validation Rules
export const validationRules = () => {
  let passwordValidation = {
    format: {
      pattern:
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!_#%*?&])[A-Za-z\d@_#$!%*?&]*$/,
      flags: "i",
      message:
        "^Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
    },
    length: {
      minimum: 7,
      tooShort: "must contain alteast 7 character",
      maximum: 12,
      tooLong: "must contain less than 12 character",
    },
  };
  return {
    emailId: {
      presence: {
        allowEmpty: false,
        message: "^Email is required",
      },
      format: {
        pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.[a-zA-Z0-9]{2,4})*$/,
        flags: "i",
        message: "^Please enter valid email id",
      },
    },
    password: {
      presence: {
        allowEmpty: false,
        message: "^Password is required",
      },
      ...passwordValidation,
    },
  };
};
