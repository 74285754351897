import {
  CommonPageLoader,
  NormalButton,
  NormalSearch,
  TableWrapper,
  NormalSelect,
} from "component/common";
import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { transactionInformationTableHeader } from "../../../service/helpers/Constants";
import SearchIcon from "@material-ui/icons/Search";
import { bindActionCreators } from "redux";
import { getRetailerList } from "action/UserMangementAct";
import { transactionInformationApiCall } from "../../../action/TransactionInformationAct";
import moment from "moment";

function TransactionInformationManage({
  transactionInformationApiCall,
  getRetailerListApi,
}) {
  const [transactionList, setTransactionList] = useState([]);
  const [searchInfo, setSearchInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState(null);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [sort, setSort] = useState(true);
  const [retailerList, setretailerList] = useState([]);
  const [retailer, setRetailer] = useState("");

  //lifecycles
  useEffect(() => {
    fetchData();
  }, [page, pageLimit, searchInfo, sort, retailer]);

  useEffect(() => {
    getRetailerListFunc();
  }, []);

  //getRetailerListFunc
  const getRetailerListFunc = () => {
    getRetailerListApi().then(({ data }) => {
      //   let resultData = [{ label: "All", value: "All" }];
      let resultData = [];
      if (data) {
        data.usersList.map((list) => {
          resultData.push({ value: list._id, label: list.name });
        });
        // if (resultData.length - 1 === data.usersList.length) {
        //   setretailerList([...resultData]);
        //   setRetailer(resultData[1].value);
        // }
        if (data.usersList.length) {
          setretailerList([...resultData]);
          setRetailer(resultData[0].value);
        }
      }
    });
  };

  //fetch data from api
  const fetchData = () => {
    setLoading(true);
    if (retailer) {
      let query = {
        pageNo: page,
        size: pageLimit,
        search: searchInfo,
        sort: sort ? 1 : -1,
        retailerId: retailer,
      };
      transactionInformationApiCall(query)
        .then(({ data, pageMeta }) => {
          if (data) {
            setLoading(false);
            setPaginationConfig(pageMeta);
            setTransactionList(data);
          } else {
            setTransactionList([]);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  //handle sorting
  const handleSort = (sort) => {
    setSort(!sort);
  };

  const handleInput = (e) => {
    const { value } = e.target || e || {};
    if (value == "All") {
      setRetailer("");
    } else {
      setRetailer(value);
    }
  };

  return (
    <div className="transaction-infomation">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex w-100">
            <NormalSearch
              placeholder={"Search"}
              value={searchInfo}
              name={"searchInfo"}
              onChange={(e) => setSearchInfo(e.target.value)}
              icon={<SearchIcon />}
            />
            <NormalSelect
              value={retailer}
              handleChange={handleInput}
              name="retailer"
              arrow={true}
              options={retailerList}
              isSearchable={true}
              className="ml-3"
              placeholder={"Select Retailer"}
            />
          </div>
          <div className="d-flex">
            <NormalButton
              label="Sort"
              sortButton
              className="ml-auto"
              onClick={() => handleSort(sort)}
            />
          </div>
        </div>
      </div>
      <div className="fileUploadTable ">
        <TableWrapper
          headerDetails={transactionInformationTableHeader}
          pageMeta={true}
          // className="time-off"
          paginationShow={true}
          paginationConfig={paginationConfig}
          handlePageChange={setPage}
          paginationChange={setPage}
          handlePageSize={(size) => {
            setPage(1);
            setPageLimit(size);
          }}
        >
          {!loading ? (
            <>
              {transactionList.length > 0 ? (
                <>
                  {transactionList.map((list, index) => (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center my-2">
                          {(page - 1) * 10 + index + 1}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center my-2">
                          {list.createdAt
                            ? moment(list.createdAt).format("DD/MM/YYYY")
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center my-2">
                          {list.createdAt
                            ? moment(list.createdAt).format("H:mm A")
                            : "-"}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center my-2">
                          {list.fileCode}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center my-2">
                          {list && list.retailerId && list.retailerId.name}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center my-2">
                          {list.transactionType}
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <td colSpan="6" className="text-center">
                  No data
                </td>
              )}
            </>
          ) : (
            <tr>
              <td colSpan="12" className="text-center">
                <CommonPageLoader />
              </td>
            </tr>
          )}
        </TableWrapper>
      </div>
    </div>
  );
}
const mapdispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      transactionInformationApiCall,
      getRetailerListApi: getRetailerList,
    },
    dispatch
  );
};

export default connect(null, mapdispatchToProps)(TransactionInformationManage);
