import React, { Component } from "react";
import sortbuttonarrow from "../../../assets/icons/sortbuttonarrow.png";
export class NormalButton extends Component {
  render() {
    const {
      className = "",
      label = "",
      onClick,
      id,
      disabled = false,
      outline = false,
      mainbg = false,
      normal = false,
      danger = false,
      greyBg = false,
      successBg = false,
      outlineDanger = false,
      isPrimay = false,
      isViewTableButton = false,
      sortButton = false,
      rightIcon = "",
    } = this.props;

    return (
      <div className="w-100">
        <button
          id={id}
          className={`btn cursor-pointer 
          ${outline ? "outline-btn" : ""} 
          ${outlineDanger ? "outline-btn-danger" : ""} 
          ${mainbg ? "mainbg-btn" : ""} 
          ${normal ? "normal-btn" : ""}
          ${danger ? "danger-btn" : ""} 
          ${greyBg ? "grey-btn" : ""}
          ${successBg ? "success-btn" : ""}
          ${isPrimay ? "primary-btn" : ""}
          ${isViewTableButton ? "view-table-btn" : ""}
          ${sortButton ? "sortButton" : ""}
          ${className}`}
          onClick={onClick}
          disabled={disabled}
        >
          {label}
          {rightIcon !== "" ? (
            <span className={`btn-right-icon ${rightIcon}`}></span>
          ) : null}
          {sortButton ? (
            <span className="ml-3">
              <img src={sortbuttonarrow} />
            </span>
          ) : null}
        </button>
      </div>
    );
  }
}
