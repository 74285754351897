import {
  CommonPageLoader,
  NormalButton,
  NormalSearch,
  NormalSelect,
  TableWrapper,
} from "component/common";
import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import {
  getAllRetailApi,
  retailerDeleteApi,
  updateRetailer,
} from "action/RetailerManagementAct";
import { retailerManagementHeader } from "../../../service/helpers/Constants";
import { bindActionCreators } from "redux";
import { useEffect } from "react";
import moment from "moment";
import { DeletePopup } from "component/common/Modal/DeletePopup";
import deleteIcon from "assets/images/delete.svg";
import CreateOrUpdateRetailer from "./createOrUpdateRetailer";
import { Toast } from "service/toast";

const statusOption = [
  { label: "Enabled", value: true },
  { label: "Disabled", value: false },
];
function RetailerManage({
  getAllRetailApi,
  retailerDeleteApi,
  updateRetailer,
  access: { deleteAccess = false, createAccess = false, updateAccess = false },
}) {
  const [retailData, setretailData] = useState([]);
  const [searchRetailer, setSearchRetailer] = useState("");
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState(null);
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isPopupLoading, setIsPopupLoading] = useState(false);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [userDataRetailer, setuserDataRetailer] = useState(null);
  const [isView, setIsView] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);

  useEffect(() => {
    fetchRetailer();
  }, [page, searchRetailer, pageLimit]);

  //Data fetch from api
  const fetchRetailer = async () => {
    setLoading(true);
    let query = {
      pageNo: page,
      pageLimit: pageLimit,
      search: searchRetailer,
    };
    await getAllRetailApi(query)
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          setPaginationConfig(data.pageMeta);
          setretailData(data.usersList);
          setLoading(false);
        } else {
          setretailData([]);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //handle Toggle
  const handleToggle = (emailId) => {
    if (emailId) {
      setDeleteId(emailId);
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setDeleteId(null);
    }
  };

  //handle delete
  const handleDeleteFile = () => {
    if (deleteId) {
      setIsPopupLoading(true);
      retailerDeleteApi(deleteId)
        .then(() => {
          setIsOpen(false);
          fetchRetailer();
          setIsPopupLoading(false);
        })
        .catch(() => {
          setIsPopupLoading(false);
        });
    }
  };

  //handle input change
  const handleInput = (index) => {
    retailData[index].status = !retailData[index].status;
    setretailData([...retailData]);
    let body = {
      _id: retailData[index]._id,
      status: retailData[index].status,
    };
    updateRetailer(body)
      .then(() => {
        setretailData(retailData);
      })
      .catch(() => {
        retailData[index].status = !retailData[index].status;
        setretailData([...retailData]);
      });
  };

  //handle edit
  const handleEdit = (editData) => {
    setuserDataRetailer(editData);
    setIsAddPopupOpen(true);
  };
  return (
    <div className="retailer-management">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <NormalSearch
            placeholder={"Search"}
            value={searchRetailer}
            name={"searchRetailer"}
            onChange={(e) => setSearchRetailer(e.target.value)}
            icon={<SearchIcon />}
          />
          {createAccess ? (
            <NormalButton
              label="Add New Retailer"
              normal
              className="ml-auto"
              onClick={() => setIsAddPopupOpen(true)}
            />
          ) : null}
        </div>
      </div>
      <div>
        <div className="fileUploadTable">
          <TableWrapper
            headerDetails={retailerManagementHeader}
            pageMeta={true}
            uploadTable="retailerTable"
            className="time-off"
            paginationShow={true}
            paginationConfig={paginationConfig}
            handlePageChange={setPage}
            paginationChange={setPage}
            handlePageSize={(size) => {
              setPage(1);
              setPageLimit(size);
            }}
          >
            {!loading ? (
              <>
                {retailData.length > 0 ? (
                  <>
                    {retailData.map((list, index) => (
                      <tr>
                        <td>
                          <div
                            className={`pointer-cursor d-flex align-items-center h-100 fs-14 text-c2 justify-content-center ${
                              !list.status ? "disabled-color" : ""
                            }`}
                          >
                            {(page - 1) * 10 + index + 1}
                          </div>
                        </td>
                        <td>
                          {updateAccess ? (
                            <div
                              className={`d-flex align-items-center h-100 fs-14 ${
                                !list.status
                                  ? "disabled-color"
                                  : "text-blue-2c cursor-pointer text-underline"
                              } fw-500 justify-content-center`}
                              onClick={() => {
                                list.status && handleEdit(list);
                              }}
                            >
                              {list.name}
                            </div>
                          ) : (
                            <div
                              className={`d-flex align-items-center h-100 fs-14 ${
                                !list.status ? "disabled-color" : "text-blue-2c"
                              } fw-500 justify-content-center`}
                            >
                              {list.name}
                            </div>
                          )}
                        </td>
                        <td>
                          <div
                            className={`d-flex align-items-center h-100 fs-14 text-c2 justify-content-center ${
                              !list.status ? "disabled-color" : ""
                            }`}
                          >
                            {list.emailId}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`d-flex align-items-center h-100 fs-14 text-c2 justify-content-center ${
                              !list.status ? "disabled-color" : ""
                            }`}
                          >
                            {list.createdAt
                              ? moment(list.createdAt).format("DD/MM/YYYY")
                              : "-"}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`d-flex align-items-center h-100 fs-14 text-c2 justify-content-center ${
                              !list.status ? "disabled-color" : ""
                            }`}
                          >
                            {list.createdAt
                              ? moment(list.createdAt).format("H:mm A")
                              : "-"}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`d-flex align-items-center text-center h-100 fs-14 text-c2 justify-content-center ${
                              !list.status ? "disabled-color" : ""
                            }`}
                          >
                            {list.subscriptionKey}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`d-flex align-items-center h-100 fs-14 fw-600 justify-content-center ${
                              !list.status ? "disabled-color" : "text-success"
                            }`}
                          >
                            {list.status ? "Active" : "In-active"}
                          </div>
                        </td>
                        <td>
                          <div className=" h-100 fs-14 text-c2 table-select_dropdown">
                            <NormalSelect
                              value={list.status}
                              handleChange={() => handleInput(index)}
                              name="role"
                              isBoxShadow
                              arrow={true}
                              disabled={!updateAccess}
                              isTableIcon
                              options={statusOption}
                              isSearchable={false}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center">
                            {deleteAccess ? (
                              <NormalButton
                                label="Delete"
                                outlineDanger={true}
                                className="mr-3"
                                onClick={() => handleToggle(list._id)}
                              />
                            ) : (
                              ""
                            )}
                            {/* <NormalButton
                              className="mr-2"
                              label="View"
                              isViewTableButton
                              onClick={() => {
                                setIsView(true);
                                handleEdit(list);
                              }}
                            /> */}
                            <NormalButton
                              label="Edit"
                              isViewTableButton
                              onClick={() => {
                                setIsView(true);
                                handleEdit(list);
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <td colSpan="9" className="text-center">
                    No data
                  </td>
                )}
              </>
            ) : (
              <tr>
                <td colSpan="9" className="text-center">
                  <CommonPageLoader />
                </td>
              </tr>
            )}
          </TableWrapper>
        </div>
      </div>

      {/* delete popup */}
      <DeletePopup
        isOpen={isOpen}
        title="Are you sure you want to delete"
        toggle={handleToggle}
        deleteIcon={deleteIcon}
        type="File"
        handleChange={handleDeleteFile}
        isPopupLoading={isPopupLoading}
      />

      {/* view, create and update popup */}
      <CreateOrUpdateRetailer
        isOpen={isAddPopupOpen}
        toggle={() => {
          setIsAddPopupOpen(false);
          setIsView(false);
          setuserDataRetailer(null);
        }}
        isEdit={userDataRetailer ? true : false}
        isView={isView}
        userDataRetailer={userDataRetailer}
        handleCloseFunc={() => {
          fetchRetailer();
        }}
      />
    </div>
  );
}
const mapdispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllRetailApi,
      retailerDeleteApi,
      updateRetailer,
    },
    dispatch
  );
};
export default connect(null, mapdispatchToProps)(RetailerManage);
