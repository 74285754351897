import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addRetailer, updateRetailer } from "action/RetailerManagementAct";
import { Modal, ModalBody } from "reactstrap";
import { NormalButton, NormalInput } from "component/common";
import validate from "service/validation";
import ErrorComponent from "component/common/ErrorComponent";
import { CustomPhoneInput } from "component/common/CustomPhoneInput";
import { validationRules } from "./validate";

function CreateOrUpdateRetailer(props) {
  let {
    className = "modal-dialog-centered user-popup",
    isOpen = false,
    toggle,
    addRetailer,
    handleCloseFunc,
    userDataRetailer,
    isView = false,
    isEdit = false,
    updateRetailer,
  } = props;

  const [retailerDetails, setretailerDetails] = useState({
    name: "",
    contactPerson: "",
    siteUrl: "",
    emailId: "",
    mobileNo: "",
    address: "",
    pincode: "",
  });
  const [errors, setErrors] = useState([]);
  const [isButtonClicked, setisButtonClicked] = useState(false);

  //handle Input
  const handleInput = (e) => {
    const { name, value } = e.target || e || {};

    const tempErrors = { ...errors };

    tempErrors[name] = undefined;
    setretailerDetails({ ...retailerDetails, [name]: value });
    setErrors({ ...errors, ...tempErrors });
  };

  //validate function
  const validateFields = (data) => {
    const fieldInvalidList = validate(data, validationRules());

    if (fieldInvalidList !== undefined) {
      const errors = {
        ...fieldInvalidList,
      };
      setErrors({ ...errors, ...fieldInvalidList });
    }

    return !fieldInvalidList;
  };

  //lifecycles
  useEffect(() => {
    if (isOpen) {
      if (userDataRetailer) {
        userDataRetailer.pincode = userDataRetailer.pincode
          ? userDataRetailer.pincode.toString()
          : "";
        setretailerDetails(userDataRetailer);
      }
    }
  }, [isOpen, userDataRetailer]);

  //handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    let body = {
      ...retailerDetails,
    };
    if (!validateFields(body)) return;
    setisButtonClicked(true);
    if (isEdit) {
      delete body.createdAt;
      delete body.subscriptionKey;
      updateRetailer(body)
        .then(() => {
          handleClose();
          handleCloseFunc();
          setisButtonClicked(false);
        })
        .catch(() => {
          setisButtonClicked(false);
        });
    } else {
      addRetailer(body)
        .then(() => {
          handleClose();
          handleCloseFunc();
          setisButtonClicked(false);
        })
        .catch(() => {
          setisButtonClicked(false);
        });
    }
  };

  //handle close
  const handleClose = () => {
    setretailerDetails({
      name: "",
      contactPerson: "",
      siteUrl: "",
      emailId: "",
      mobileNo: "",
      address: "",
      pincode: "",
    });
    setErrors([]);
    props.toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className={className} size="lg">
      <ModalBody className="p-0">
        <div className="d-flex justify-content-end">
          <i
            className="icon-close fs-16 cursor-pointer"
            onClick={() => handleClose()}
          ></i>
        </div>
        <div>
          <p className="text-blue-2c fs-24 text-center line-height-24 fw-600">
            {isView
              ? "View Detail"
              : isEdit
              ? "Update Retailer"
              : "Create New Retailer"}
          </p>
        </div>
        <div className="row">
          <div className="col-6">
            <NormalInput
              label="Name *"
              value={retailerDetails.name}
              name="name"
              onChange={handleInput}
              // disabled={isView}
            />
            {errors.name && <ErrorComponent message={errors.name[0]} />}
          </div>

          <div className="col-6">
            <NormalInput
              label="Retailer Contact person"
              value={retailerDetails.contactPerson}
              name="contactPerson"
              onChange={handleInput}
              disabled={isView}
            />
            {errors.contactPerson && (
              <ErrorComponent message={errors.contactPerson[0]} />
            )}
          </div>
          <div className="col-6">
            <NormalInput
              label="Retailers site"
              value={retailerDetails.siteUrl}
              name="siteUrl"
              onChange={handleInput}
              disabled={isView}
            />
            {errors.siteUrl && <ErrorComponent message={errors.siteUrl[0]} />}
          </div>
          <div className="col-6">
            <CustomPhoneInput
              label="Contact number *"
              value={retailerDetails.mobileNo}
              onChange={(phone) => {
                errors.mobileNo = undefined;
                setErrors(errors);
                setretailerDetails({ ...retailerDetails, mobileNo: phone });
              }}
              disabled={isView}
            />
            {errors.mobileNo && <ErrorComponent message={errors.mobileNo[0]} />}
          </div>
          <div className="col-6">
            <NormalInput
              label="email id *"
              value={retailerDetails.emailId}
              name="emailId"
              onChange={handleInput}
              disabled={isView}
            />
            {errors.emailId && <ErrorComponent message={errors.emailId[0]} />}
          </div>

          <div className="col-6">
            <NormalInput
              label="Address *"
              value={retailerDetails.address}
              name="address"
              onChange={handleInput}
              disabled={isView}
            />
            {errors.address && <ErrorComponent message={errors.address[0]} />}
          </div>
          <div className="col-6">
            <NormalInput
              label="Pin Code *"
              value={retailerDetails?.pincode}
              name="pincode"
              onChange={handleInput}
              disabled={isView}
              type="number"
            />
            {errors.pincode && <ErrorComponent message={errors.pincode[0]} />}
          </div>
        </div>

        <div className="d-flex justify-content-center mt-5">
          <NormalButton
            greyBg
            label="Back"
            className="mr-4 ml-auto"
            onClick={() => handleClose()}
            disabled={isButtonClicked}
          />
          <NormalButton
            normal
            label={isEdit ? "Update" : "Add"}
            onClick={handleSubmit}
            disabled={isButtonClicked}
          />
        </div>
      </ModalBody>
    </Modal>
  );
}
const mapdispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addRetailer,
      updateRetailer,
    },
    dispatch
  );
};

export default connect(null, mapdispatchToProps)(CreateOrUpdateRetailer);
