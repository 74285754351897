import React, { useEffect } from "react";
import FileUploadManage from "component/Dashboard/FileUpload";
import { getAccessdata } from "service/helperFunctions";
import { logout } from "service/utilities";
export function FileUpload() {
  let access = getAccessdata("product-management");
  useEffect(() => {
    if (!access.viewAccess) {
      logout();
    }
  }, []);
  return <FileUploadManage access={access} />;
}
