//validation Rules
export const validationRules = () => {
  return {
    emailId: {
      presence: {
        allowEmpty: false,
        message: "^Email is required",
      },
      format: {
        pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.[a-zA-Z0-9]{2,4})*$/,
        flags: "i",
        message: "^Please enter valid email id",
      },
    },
  };
};
