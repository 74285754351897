import React, { useEffect, useState } from "react";
import { CommonPageLoader, NormalSelect, TableWrapper } from "component/common";
import { getAccessdata } from "service/helperFunctions";
import { fileUploadManage } from "../../../service/helpers/Constants";
import { NormalButton } from "../../../component/common/NormalButton/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { productApiUpload, productDeleteApi } from "../../../action/FileUpload";
import SearchIcon from "@material-ui/icons/Search";
import { NormalSearch } from "component/common/NormalSearch";
import { DeletePopup } from "component/common/Modal/DeletePopup";
import deleteIcon from "assets/images/delete.svg";
import { getRetailerList } from "action/UserMangementAct";
import { getFileManagementList } from "action/FileManagementAct";
import { DragandDrop } from "component/common/DragDrop";
import { DowloadOrUploadSuccess } from "component/common/Modal/DowloadOrUploadSuccess";

function FileUploadManage({
  getFileManagementListApi,
  productApiUpload,
  productDeleteApi,
  access: { deleteAccess = false },
  getRetailerListApi,
}) {
  const [fileList, setFileList] = useState([]);
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationConfig, setPaginationConfig] = useState(null);
  const [searchProduct, setSearchProduct] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [isPopupLoading, setIsPopupLoading] = useState(false);
  const [headerList, setHeaderList] = useState(fileUploadManage);
  const [pageLimit, setPageLimit] = useState(10);
  const [retailerList, setretailerList] = useState([]);
  const [retailer, setRetailer] = useState("");
  const [showDargdrop, setShowDargdrop] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isUploadSuccessPopup, setIsUploadSuccessPopup] = useState(false);
  const [fileUploadResponse, setFileUploadResponse] = useState(null);
  const [isDownloadPopupOpen, setIsDownLoadPopup] = useState(false);

  useEffect(() => {
    fetchAllUser();
    fetchFileList();
  }, [page, searchProduct, pageLimit, retailer, searchValue, pageLimit]);

  //access permission handle
  useEffect(() => {
    if (!deleteAccess) {
      headerList.splice(headerList.length - 1, 1);
      setHeaderList([...headerList]);
    }
  }, []);

  useEffect(() => {
    getRetailerListFunc();
  }, []);

  //getRetailerListFunc
  const getRetailerListFunc = () => {
    getRetailerListApi().then(({ data }) => {
      // let resultData = [{ label: "All", value: "All" }];
      let resultData = [];

      if (data) {
        data.usersList.map((list) => {
          resultData.push({ value: list._id, label: list.name });
        });
        // if (resultData.length - 1 === data.usersList.length) {
        //   setretailerList([...resultData]);
        //   setRetailer(resultData[1].value);
        // }
        if (data.usersList.length) {
          setretailerList([...resultData]);
          setRetailer(resultData[0].value);
        }
      }
    });
  };

  //fetch Files List
  const fetchFileList = () => {
    setLoading(true);
    let query = {
      size: pageLimit,
      pageNo: page,
      search: searchValue,
    };
    getFileManagementListApi(query)
      .then(({ data, pageMeta }) => {
        if (data) {
          setPaginationConfig(pageMeta);
          setFileList(data);
        } else {
          setFileList([]);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  //fetch data from api
  const fetchAllUser = () => {
    setLoading(true);
    if (retailer) {
      let query = {
        size: pageLimit,
        pageNo: page,
        search: searchProduct,
        retailerId: retailer,
      };
      productApiUpload(query)
        .then(({ data, pageMeta }) => {
          setLoading(false);
          if (data) {
            setPaginationConfig(pageMeta);
            setUser(data);
            setLoading(false);
          } else {
            setUser([]);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  //handle delete
  const handleDeleteFile = () => {
    if (deleteId) {
      setIsPopupLoading(true);
      productDeleteApi(deleteId)
        .then(() => {
          setIsOpen(false);
          fetchAllUser();
          setIsPopupLoading(false);
        })
        .catch(() => {
          setIsPopupLoading(false);
        });
    }
  };

  //handle toggle
  const handleToggle = (id) => {
    if (id) {
      setDeleteId(id);
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setDeleteId(null);
    }
  };

  const handleInput = (e) => {
    const { value } = e.target || e || {};
    if (value == "All") {
      setRetailer("");
    } else {
      setRetailer(value);
    }
  };

  //upload Response Data
  const uploadResponseData = (uploadResData) => {
    setFileUploadResponse(uploadResData);
  };

  let fileUploadAccess = getAccessdata("file-management");
  console.log(retailer, "retailerretailer");
  return (
    <div className="product-maanagement">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex w-100">
            <NormalSearch
              placeholder={"Search"}
              value={searchProduct}
              name={"searchProduct"}
              onChange={(e) => setSearchProduct(e.target.value)}
              icon={<SearchIcon />}
            />

            <NormalSelect
              value={retailer}
              handleChange={handleInput}
              name="retailer"
              arrow={true}
              options={retailerList}
              isSearchable={true}
              className="ml-3"
              placeholder={"Select Retailer"}
            />
          </div>
          {fileUploadAccess.uploadAccess ? (
            <NormalButton
              label="Upload New File"
              normal
              className="ml-auto"
              onClick={() => setShowDargdrop(!showDargdrop)}
            />
          ) : null}
        </div>
      </div>
      <div className="fileUploadTable">
        <TableWrapper
          headerDetails={headerList}
          pageMeta={true}
          uploadTable="uploadTable"
          paginationShow={true}
          isUploadPage={true}
          paginationConfig={paginationConfig}
          handlePageChange={setPage}
          paginationChange={setPage}
          handlePageSize={(size) => {
            setPage(1);
            setPageLimit(size);
          }}
        >
          {!loading ? (
            <>
              {user.length > 0 ? (
                <>
                  {user.map((list, index) => (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center mt-4">
                          {list.baseComponentFileCode}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center mt-4">
                          {list.baseComponent}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center mt-4">
                          {list.baseComponentCategory}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex h-100 fs-14 text-c2 justify-content-center flex-column mt-1">
                          <div className="materialPriceContentMaterial ">
                            {list.baseComponentMaterialPrice.map((data, i) => (
                              <div className="flex-column">
                                <div>
                                  {data.material} - {data.color}
                                </div>
                                <div>{data.price}</div>
                                {/* <div className="text-center">{data.color}</div> */}
                              </div>
                            ))}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className=" align-items-center h-100 fs-14 text-c2 justify-content-center">
                          {list.groupedAddOnComponent.map((data, index) => (
                            <div className="components mt-4">
                              <strong>{data.attribute_1}</strong>-
                              {data.attribute_2}-{data.attribute_3}
                            </div>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex h-100 fs-14 text-c2 justify-content-center flex-column">
                          {list.groupedAddOnComponent.map((data, index) => (
                            <div className="materialPriceContentMaterial">
                              {data?.materialprice.map((item, index) => (
                                <div className="d-flex flex-column mb-2">
                                  <div>
                                    {item.material} - {item.color}
                                  </div>
                                  <div>{item.price}</div>
                                  {/* <div className="text-center">{item.color}</div> */}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center flex-column mt-4">
                          {list.addOnComponent.map((item, index) => (
                            <div>{item.addOnComponent}</div>
                          ))}
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center ">
                          {list.addOnComponent.map((data, i) => (
                            <div className="materialPriceContentMaterial">
                              {data.materialPrice.map((list, s) => (
                                <div className="d-flex flex-column">
                                  <div>
                                    {list.material} - {list.color}
                                  </div>
                                  <div>{list.price}</div>
                                  {/* <div className="text-center">{list.color}</div> */}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      </td>
                      {deleteAccess ? (
                        <td>
                          <div className="d-flex align-items-center h-100 fs-14 text-c2 justify-content-center  mt-3">
                            <NormalButton
                              label="Delete"
                              outlineDanger={true}
                              onClick={() => handleToggle(list._id)}
                            />
                          </div>
                        </td>
                      ) : null}
                    </tr>
                  ))}
                </>
              ) : (
                <td colSpan="12" className="text-center">
                  No data
                </td>
              )}
            </>
          ) : (
            <tr>
              <td colSpan="12" className="text-center">
                <CommonPageLoader />
              </td>
            </tr>
          )}
        </TableWrapper>
      </div>
      {/* Drag and Drop popup */}
      <DragandDrop
        showDargdrop={showDargdrop}
        setShowDargdrop={() => {
          setShowDargdrop(!showDargdrop);
        }}
        fetchFileList={() => fetchFileList()}
        responseData={uploadResponseData}
        handleSuccessPopup={() => setIsUploadSuccessPopup(true)}
      />
      {/* Dowload success popup  */}
      <DowloadOrUploadSuccess
        isOpen={isDownloadPopupOpen}
        toggle={() => setIsDownLoadPopup(!isDownloadPopupOpen)}
        title="Downloded Successfully"
        type="download"
        handleChange={() => {
          setIsDownLoadPopup(!isDownloadPopupOpen);
          fetchFileList();
        }}
      />
      {/* Upload success popup  */}
      <DowloadOrUploadSuccess
        isOpen={isUploadSuccessPopup}
        toggle={() => setIsUploadSuccessPopup(!isUploadSuccessPopup)}
        title="Upload Successful"
        type="upload"
        handleChange={() => {
          setIsUploadSuccessPopup(false);
          fetchFileList();
        }}
        fileUploadResponse={fileUploadResponse}
      />
      {/* delete popup */}
      <DeletePopup
        isOpen={isOpen}
        title="Are you sure you want to delete"
        selectedFileName={selectedFileName}
        toggle={handleToggle}
        deleteIcon={deleteIcon}
        type="File"
        handleChange={handleDeleteFile}
        isPopupLoading={isPopupLoading}
      />
    </div>
  );
}
const mapdispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFileManagementListApi: getFileManagementList,
      productApiUpload,
      productDeleteApi,
      getRetailerListApi: getRetailerList,
    },
    dispatch
  );
};

export default connect(null, mapdispatchToProps)(FileUploadManage);
