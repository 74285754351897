import React, { Component } from "react";
import { history } from "service/helpers";
// import { logout } from "service/utilities";
import { Link } from "react-router-dom";
import "./index.scss";
const logo = require("assets/images/logo.png");

export class Navbar extends Component {
  render() {
    return (
      <div className="navbar">
        <div className="container navbar-container">
          <div className="logo">
            <img
              src={logo}
              alt="logo"
              onClick={() => {
                history.push("/auth/login");
              }}
            />
          </div>
          <div className="nav-links">
            <ul>
              <li>
                <Link to="/auth/login" className={`active`}>
                  Login
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
