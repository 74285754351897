import TransactionInformationManage from "component/Dashboard/TransactionInformation";
import React, { useEffect } from "react";
import { getAccessdata } from "service/helperFunctions";
import { logout } from "service/utilities";
export function TransactionInformation() {
  let access = getAccessdata("transaction-management");
  useEffect(() => {
    if (!access.viewAccess) {
      logout();
    }
  }, []);
  return <TransactionInformationManage access={access} />;
}
