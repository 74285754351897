//add Query
export const addQuery = (dataObject, apiObject) => {
  if (!dataObject) {
    return "";
  }

  const keys = [
    "page",
    "pageNo",
    "size",
    "search",
    "pageLimit",
    "sort",
    "retailerId",
  ];

  keys.map((key) => {
    if (dataObject.hasOwnProperty(key) && typeof dataObject[key] != "object") {
      if (apiObject.query.hasOwnProperty(key)) {
        apiObject.addQuery = { key, payload: dataObject[key] };
      }
    } else {
      dataObject[key] &&
        Object.keys(dataObject[key]).map((keyName) => {
          if (apiObject.query.hasOwnProperty(keyName)) {
            apiObject.addQuery = {
              key: keyName,
              payload: dataObject[key][keyName],
            };
          }
        });
    }
  });
};

//download Base Url
// export const downloadBaseUrl = "http://doodlebluelive.com:2380";
export const downloadBaseUrl = "https://apicreatorcms.ole-solutions.com";
//generate Query
export const generateQuery = (query) => {
  let url = "";

  if (query.hasOwnProperty("url_id")) {
    url = `/${query.url_id}`;
  }

  return (
    url +
    Object.keys(query).reduce((accumulator, key, index) => {
      if (
        query[key] == "" ||
        query[key] == null ||
        key == "url_id" ||
        (query[key] !== null && query[key].toString().trim() == "")
      ) {
        return accumulator;
      } else {
        return accumulator + `${index != 0 ? "&" : "?"}${key}=${query[key]}`;
      }
    }, "")
  );
};

//get Access data
export const getAccessdata = (service) => {
  let totalAccessData = JSON.parse(localStorage.getItem("rolePermission"));

  let {
    productManagementAccess = {},
    transactionAccess = {},
    fileManagementAccess = {},
    retailerAccess = {},
    userAccess = {},
  } = totalAccessData;

  let accessData = {};
  if (service === "product-management") {
    const { view = false } = productManagementAccess;
    let access = {
      viewAccess: view,
      deleteAccess: productManagementAccess.delete
        ? productManagementAccess.delete
        : false,
    };
    accessData = access;
  } else if (service === "transaction-management") {
    const { view = false } = transactionAccess;
    let access = {
      viewAccess: view,
    };
    accessData = access;
  } else if (service === "file-management") {
    let {
      view = false,
      upload = false,
      download = false,
    } = fileManagementAccess;
    let access = {
      viewAccess: view,
      deleteAccess: fileManagementAccess.delete
        ? fileManagementAccess.delete
        : false,
      uploadAccess: upload,
      downloadAccess: download,
    };
    accessData = access;
  } else if (service === "retailer-management") {
    let { view = false, create = false, update = false } = retailerAccess;
    let access = {
      viewAccess: view,
      deleteAccess: retailerAccess.delete ? retailerAccess.delete : false,
      createAccess: create,
      updateAccess: update,
    };
    accessData = access;
  } else if (service === "user-management") {
    let { view = false, create = false, update = false } = userAccess;
    let access = {
      viewAccess: view,
      deleteAccess: userAccess.delete ? userAccess.delete : false,
      updateAccess: update,
      createAccess: create,
    };
    accessData = access;
  }
  return accessData;
};
