export const validationRules = () => {
  return {
    emailId: {
      presence: {
        allowEmpty: false,
        message: "^Email is required",
      },
      format: {
        pattern: /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.[a-zA-Z0-9]{2,4})*$/,
        flags: "i",
        message: "^Please enter valid email id",
      },
    },
    name: {
      presence: {
        allowEmpty: false,
        message: "^Email is required",
      },
      length: {
        minimum: 3,
        tooShort: "must contain alteast 3 character",
        maximum: 50,
        tooLong: "must contain less than 50 character",
      },
    },
    role: {
      presence: {
        allowEmpty: false,
        message: "^Role is required",
      },
    },
    mobileNo: {
      presence: {
        allowEmpty: false,
        message: "^Phone Number is required",
      },
      length: {
        maximum: 12,
        tooLong: "^Mobile number must contain less than 12 character",
      },
    },
  };
};
